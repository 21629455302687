import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './case.scss';
import PageLayout from './pageLayout';
import { HemaLabel, FormText, Button} from '../../utils';
import DataTable from 'react-data-table-component';
import { getCaseByAccessionNumberAction, updateCaseResultAction, getCaseReportTemplateAction } from '../../Actions/case';
import { useNavigate } from 'react-router-dom';
import { PrintIcon } from '../../HemeIconLibrary';
import { printLabel } from './printLabel';


export default function TwoColumnsWithCards() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [accessionNumber, setAccessionNumber] = useState('');
  const [caseItem, setCaseItem] = useState([]);
  const [inputs, setInputs] = useState([]);
  const { common } = useSelector((state) => state);


  const fieldNames = ['specimenTypeName', 'quantity',
    'collectedUtc', 'specimenConditionName', 'test']; // Exclude specific columns


  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const columns = [
    ...fieldNames.map((field) => ({
      name: capitalizeFirstLetter(field.replace(/([A-Z])/g, ' $1')),
      selector: (row) => row[field],
      sortable: true,
      cell: (row) => <div title={row[field]}>{row[field]}</div>,
    })),
    {
      name: (
        <div className="w-full">
          <div className="py-[10px] flex items-center justify-end w-full border-solid border-[#DEE2E6] pl-[10px] pr-[30px]">
            <span className="text-xs font-normal">Actions</span>
          </div>
        </div>
      ),
      cell: (row) => (
        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
          <Button 
            type="button"
            disabled={!row.accessionNumber}
            icon={<PrintIcon color="#605DAF" />} 
            cta={() => printLabel(row)} 
          />
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  const quickAccess = async () => {
    // let item = await getCaseByAccessionNumberAction('test525-000109');
    let item = await getCaseByAccessionNumberAction(accessionNumber);
    console.log('quickAccess', item);
    setCaseItem(item?.data ? item.data : {});
  }

  useEffect(() => {
    setInputs(caseItem ? [caseItem] : []);
  }, [caseItem]);

  return (
    <PageLayout>
      {common?.permissions?.['Study Management']?.write && (

        <div className="grid grid-cols-1 gap-x-8 gap-y-8 py-10 md:grid-cols-5">

          <div className=" px-4 w-full sm:max-w-xs">
            <HemaLabel
              text="Accession Number"
              className={'mb-[10px]'}
            />

          </div>
          <div className=" px-4 w-full sm:max-w-xs">
            <FormText
              type="text"
              name="Accession Number"
              onBlur={(val) => { setAccessionNumber(val.target.value); }}
            //disabled={true}
            />
          </div>
          <div className=" px-4 w-full sm:max-w-xs">
            <button
              type="submit"
              onClick={quickAccess}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Quick Access
            </button>
          </div>
        </div>

      )}

      <div className="divide-y divide-gray-900/10">

        <div className="grid grid-cols-1 gap-x-8 gap-y-8 py-10 md:grid-cols-5">
          <div className="px-4 sm:px-0">
            <h2 className="text-base/7 font-semibold text-gray-900">Case</h2>
            <p className="mt-1 text-sm/6 text-gray-600">
              Case Information.
            </p>
          </div>

          <form className="bg-white ring-1 shadow-xs ring-gray-900/5 sm:rounded-xl md:col-span-3">
            <div className="px-4 py-6 sm:p-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                <div className="col-span-full">
                  <div className="form-row">
                    <div className="field-container">
                      <HemaLabel
                        text="Sponsor Name"
                        className={'mb-[10px]'}
                      />
                      <FormText
                        type="text"
                        name="sponsorName"
                        value={caseItem?.sponsorName ? caseItem.sponsorName : ''}
                        disabled={true}
                      />
                    </div>
                    <div className="field-container">
                      <HemaLabel
                        text="Study Name"
                        className={'mb-[10px]'}
                      />
                      <FormText
                        type="text"
                        name="studyName"
                        value={caseItem.studyName ? caseItem.studyName : ''}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-span-full">
                  <div className="form-row">
                    <div className="field-container">
                      <HemaLabel
                        text="Site Name"
                        className={'mb-[10px]'}
                      />
                      <FormText
                        type="text"
                        name="siteName"
                        value={caseItem.siteName ? caseItem.siteName : ''}
                        disabled={true}
                      />
                    </div>
                    <div className="field-container">
                      <HemaLabel
                        text="Principal Investigator"
                        className={'mb-[10px]'}
                      />
                      <FormText
                        type="text"
                        name="principalInvestigator"
                        value={caseItem.piName ? caseItem.piName : ''}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-span-full">
                  <div className="form-row">
                    <div className="field-container">
                      <HemaLabel
                        text="Time point"
                        className={'mb-[10px]'}
                      />
                      <FormText
                        type="text"
                        name="timePoint"
                        value={caseItem?.timPointName ? caseItem.timPointName : 'N/A'}
                        disabled={true}
                      />
                    </div>
                    <div className="field-container">
                      <HemaLabel
                        text="Subject Number"
                        className={'mb-[10px]'}
                      />
                      <FormText
                        type="text"
                        name="subjectNumber"
                        value={caseItem.subjectNumber ? caseItem.subjectNumber : ''}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-span-full">
                  <div className="form-row">
                    <div className="field-container">
                      <HemaLabel
                        text="Sex"
                        className={'mb-[10px]'}
                      />
                      <FormText
                        type="text"
                        name="sex"
                        value={caseItem?.sex}
                        disabled={true}
                      />
                    </div>
                    <div className="field-container">
                      <HemaLabel
                        text="Date of Birth"
                        className={'mb-[10px]'}
                      />
                      <FormText
                        type="text"
                        name="dateOfBirth"
                        value={caseItem.dateOfBirth ? caseItem.dateOfBirth : ''}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </form>
        </div>

        <div className="grid grid-cols-1 gap-x-8 gap-y-8 py-10 md:grid-cols-5">
          <div className="px-4 sm:px-0">
            <h2 className="text-base/7 font-semibold text-gray-900">Samples</h2>
            <p className="mt-1 text-sm/6 text-gray-600">Sample Information</p>
          </div>

          <form className="bg-white ring-1 shadow-xs ring-gray-900/5 sm:rounded-xl md:col-span-3">
            <div className="px-4 py-6 sm:p-8">
              {inputs?.length >= 0 &&

                <div>

                  <div style={{ marginTop: '10px' }} className="dt-body">
                    <DataTable
                      columns={columns}
                      data={inputs}
                      pagination
                      highlightOnHover
                      striped
                    // expandableRows
                    //expandableRowsComponent={ExpandedComponent}
                    />
                  </div>
                </div>
              }
            </div>

            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button type="button" className="text-sm/6 font-semibold text-gray-900"
                onClick={() => {
                  navigate('/case-management')
                }}>
                Cancel
              </button>
              <button
                type="button"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-500 "
                onClick={async () => {
                  if (caseItem?.pdfFileUrl) {
                    let toPassData = { caseItem, PDFFileUrl: caseItem?.pdfFileUrl }
                    navigate('/case-report', { state: toPassData });
                    return;
                  }
                  if (caseItem?.wordFileUrl) {
                    let toPassData = { caseItem, wordFileUrl: caseItem?.wordFileUrl }
                    navigate('/case-report', { state: toPassData });
                    return;
                  }
                  const reportFile = await getCaseReportTemplateAction(caseItem.id)
                  console.log(' Report  reportFile', reportFile.data)
                  let toPassData = { caseItem, reportFile: reportFile.data }
                  navigate('/case-report', { state: toPassData });




                }}
              >
                View report
              </button>
            </div>
          </form>
        </div>
      </div>
    </PageLayout>
  )
}
