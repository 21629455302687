import {
  getAllNotes,
  createNewNote,
} from '../Services/InternalNote';
import { store } from '../Store';
import { setAllNotes, addNewNotes } from '../Store/reducers/internalNote.js';

const createNewNoteAction = async (data) => {
  console.log("createNewNoteAction", data)
  const result = await createNewNote(data);
  if (result?.data) {
    store.dispatch(addNewNotes(result?.data));
  }
  return result
};
const getAllNotesAction = async (id) => {
    store.dispatch(setAllNotes([]));
    const result = await getAllNotes(id);
    if (result?.data) {
      store.dispatch(setAllNotes(result?.data));
    }
    console.log("getAllNotesAction result.data", result.data);
  };

export {
    createNewNoteAction,
    getAllNotesAction
};
