import {
    LabelName,
    Barcode,
    Logistics,
    StudyAlias,
    ExpiryNotification,
    NameKit,
    ChangeReason,
    TickOrange,
    CheckReady,
  } from '../../../HemeIconLibrary';
  
  export const createNewSubject = [
    {
      label: 'Site Name',
      icon: <NameKit />,
      type: 'select',
      initialValue: '',
      placeholder: 'Select site name',
      required: true,
      name: 'siteId',
      fluid: true,
      options: [],
    },
    {
      label: 'Subject Number',
      icon: <LabelName />,
      type: 'text',
      initialValue: '',
      placeholder: 'Enter subject number',
      required: true,
      name: 'subjectNumber',
    },
    {
      label: 'Date Of Birth',
      icon: <ChangeReason />,
      type: 'date',
      initialValue: '',
      placeholder: 'Enter date of birth',
      required: true,
      name: 'dateOfBirth',
    },
    {
      label: 'Sex',
      icon: <StudyAlias purple />,
      type: 'select',
      initialValue: '',
      placeholder: 'Enter sex',
      required: false,
      name: 'genderId',
      options: [],
    },
    {
      name: 'changeReason',
      label: 'Change Reason',
      placeholder: 'Type Reason',
      icon: <ChangeReason />,
      type: 'textarea',
      fluid: true,
      initialValue: '',
      required: true,
    },
  ];
  