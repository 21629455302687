/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allSubjects: [],
  subjectFilter:[],

};

export const subject = createSlice({
  name: "selectedStudySubjects",
  initialState,
  reducers: {
    setAllSubjectsReducer: (state, action) => {
      state.allSubjects = action.payload;
    },

    addNewSubjectReducer: (state, action) => {
      if (state.allSubjects) {
        state.allSubjects = [action.payload, ...state.allSubjects];
      } else {
        state.allSubjects = [action.payload];
      }
    },
    setSubjectFilter: (state, action) => {
      state.subjectFilter = action.payload;
    },
    editSubjectReducer: (state, action) => {
      state.allSubjects = state.allSubjects.map((subject) => {
        if (subject.id === action.payload.id) {
          return action.payload.data;
        } else {
          return subject;
        }
      });
    },
  },
});

export const { setAllSubjectsReducer, addNewSubjectReducer, editSubjectReducer, } = subject.actions;

export default subject.reducer;
