import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import DataTable from 'react-data-table-component';

import HemaHeadingWithSubText from '../utils/HemaHeadingWithSubText';
import { Button, FormSearch, Alert, Pagination, HemaValue, FilterColumn, NoPermissionAlert, NoDataComponent } from '../utils';
import { Add, Edit, ExpiryAlertPurple, CreateSite, SearchColor, Confirm, TableSortIcon, Delete } from '../HemeIconLibrary';
import FormContainer from '../components/Formik/formContainer';
import { setForm, editFormReducer, showSuccessReducer, setFormLoaderReducer, setFormCloseReducer } from '../Store/reducers/uiSettings';
import { setStudyFilter } from '../Store/reducers/study';

import { sortedData } from '../helpers/sort';
import { getAllStudyAction, createNewStudyAction, updateStudyAction,  } from '../Actions/study';
import { getAllCasePrefixesAction, createCasePrefixAction, getAllDocumentTemplatesAction, updateCasePrefixAction, deleteCasePrefixAction } from '../Actions/casePrefix';
import { createNewStudy } from '../components/Formik/AllForms/createNewStudy';
import { createNewCasePrefix } from '../components/Formik/AllForms/createNewCasePrefix';
import {getAlldocTemplateAction} from '../Actions/documentTemplate';
import {getAllDocTemplate} from '../Services/documentTemplate';
import createNewItemValidator from '../components/Formik/allFormValidation/createItem';
import { StudyManagementColumns } from '../components/Tables';
import { getAllSponserAction } from '../Actions/sponsers';

const StudyManagement = () => {
  const dispatch = useDispatch();
  const { uisettings, sponsers, studies, common, casePrefixes } = useSelector((state) => state);

  /* form states */
  const [CTA, setCTA] = useState();
  const [formName, setformName] = useState();
  const [formIcon, setFormIcon] = useState();
  const [formValidation, setFormValidation] = useState();
  const [updatedData, setUpdatedData] = useState();
  const [searchQuery, setsearchQuery] = useState('');
  const [searchCasePrefixQuery, setSearchCasePrefixQuery] = useState('');
  const [okBtnText, setokBtnText] = useState();
  const [okBtnIcon, setokBtnIcon] = useState();
  const [filteredData, setfilteredData] = useState([]);
  const [filteredCasePrefixes, setFilteredCasePrefixes] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
const [deleteCasePrefixId, setDeleteCasePrefixId] = useState(null);
const [deleteReason, setDeleteReason] = useState("");
  //callallEnventoryRequiredAPI
  useEffect(() => {
    (async () => {
      
      getAllSponserAction();
      getAllStudyAction();
      getAllCasePrefixesAction();
      getAllDocumentTemplatesAction();
      getAlldocTemplateAction();
    })();
  }, [dispatch]);

  // control item thresold switch
  // useEffect(() => {
  //   createNewStudy[2].disabled = !common.switch.threshold;
  // }, [common.switch.threshold]);

  // CTA item add
  const addStudyCTA = (edit) => {
    setCTA(() => async (data) => {
      const fildata = {
        ...data,
        logisticOrchestratorStatus: data.logisticOrchestratorStatus === undefined ? false : data.logisticOrchestratorStatus,
        nameAlias: data.nameAlias === undefined ? '' : data.nameAlias,
        studyCodeAlias: data.studyCodeAlias === undefined ? '' : data.studyCodeAlias,
      };
      if (edit) {
        dispatch(setFormLoaderReducer(true));
        const editSponser = await updateStudyAction(edit, {
          ...fildata,
          changeReason: fildata.changeReason.trim(),
        });
        dispatch(setFormLoaderReducer(false));
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`${editSponser.data.name} Study updated.`));
      } else {
        dispatch(setFormLoaderReducer(true));
        const createdSponser = await createNewStudyAction(fildata);
        if (createdSponser?.data?.id) {
          dispatch(setFormCloseReducer());
          dispatch(showSuccessReducer(`${createdSponser.data.name} Study added.`));
        }
        dispatch(setFormLoaderReducer(false));
      }
    });
    setFormValidation((error, values) => (errors, values) => {
      createNewItemValidator(errors, values);
      if (values?.nameAlias?.length > 50) {
        errors.nameAlias = 'Max 50 characters allowed';
      }
      if (values?.studyCodeAlias?.length > 50) {
        errors.studyCodeAlias = 'Max 50 characters allowed';
      }
      if (values.studycode?.length > 50) {
        errors.studycode = 'Max 50 characters allowed';
      }
      return errors;
    });
  };

const documentTemplates = useSelector((state) => state.docTemplate.alldocTemplate) || [];

const addCasePrefixCTA = (edit) => {
    setCTA(() => async (data) => {
        const formData = {
            ...data,
            name: data.name.trim(),
            documentTemplateId: data.documentTemplate || 0,
        };

        dispatch(setFormLoaderReducer(true));

        try {
            let response;

            if (edit) {
                response = await updateCasePrefixAction(edit, {
                    ...formData,
                    changeReason: formData.changeReason?.trim() || "Updated case prefix",
                });                
                if (response?.id) {
                    dispatch(showSuccessReducer(`Case Prefix "${response.name}" updated.`));
                    dispatch(setFormCloseReducer());
                    dispatch(getAllCasePrefixesAction());
                }
            } else {
                response = await createCasePrefixAction(formData);

                if (response?.id) {
                    dispatch(setFormCloseReducer());
                    dispatch(showSuccessReducer(`Case Prefix "${response.name}" created.`));
                    dispatch(getAllCasePrefixesAction());
                }
            }
        } catch (error) {
            console.error("Error saving Case Prefix:", error.response?.data || error.message);
        }

        dispatch(setFormLoaderReducer(false));
    });

    setFormValidation((error, values) => (errors, values) => {
        createNewItemValidator(errors, values);

        if (!values.name || values.name.length > 150) {
            errors.name = 'Case Prefix name is required and must be under 150 characters.';
        }

        if (!values.documentTemplate) {
            errors.documentTemplate = 'Document Template is required.';
        }

        return errors;
    });
};





const handleDeleteClick = (casePrefixId) => {
  setDeleteCasePrefixId(casePrefixId);
  setDeleteModalOpen(true);
};

  useEffect(() => {
    const regex = new RegExp(searchQuery, 'i');
    (async () => {
      if (searchQuery) {
        const data = studies?.allStudies?.filter((obj) => {
          for (const key in obj) {
            if (regex.test(obj[key])) {
              return true;
            }
            if (key === 'sponsor') {
              const childObj = obj[key];
              for (const k in childObj) {
                if (regex.test(childObj[k])) {
                  return true;
                }
                // return false;
              }
            }
          }
          return false;
        });
        setfilteredData(data);
      } else {
        setfilteredData(studies?.allStudies);
      }
    })();
  }, [searchQuery, studies?.allStudies]);
  // Fetch data on mount
  useEffect(() => {
    (async () => {
      getAllSponserAction();
      getAllStudyAction();
      getAllCasePrefixesAction();
    })();
  }, []);
  const setCasePrefixFilter = (filter) => ({
  type: 'SET_CASE_PREFIX_FILTER',
  payload: filter,
});

  //search & Filter for Case Prefixes**
  useEffect(() => {
    const regex = new RegExp(searchCasePrefixQuery, 'i');
    if (searchCasePrefixQuery) {
      const filtered = casePrefixes?.allCasePrefixes?.filter((obj) => 
        regex.test(obj?.name) || regex.test(obj?.documentTemplate?.name)
      );
      setFilteredCasePrefixes(filtered);
    } else {
      setFilteredCasePrefixes(casePrefixes?.allCasePrefixes);
    }
  }, [searchCasePrefixQuery, casePrefixes?.allCasePrefixes]);

  // study filter
  useEffect(() => {
    if (Object.keys(studies?.studyFilter)?.length) {
      const filterResult = studies?.allStudies?.filter((port) => {
        if (
          (studies?.studyFilter?.name?.length ? studies?.studyFilter?.name.includes(port.name) : true) &&
          (studies?.studyFilter?.sponsor?.length ? studies?.studyFilter.sponsor?.includes(port.sponsor?.name) : true) &&
          (studies?.studyFilter?.studyCode?.length ? studies?.studyFilter.studyCode?.includes(port.studyCode) : true) &&
          (studies?.studyFilter?.nameAlias?.length ? studies?.studyFilter.nameAlias?.includes(port.nameAlias) : true) &&
          (studies?.studyFilter?.nameAlias?.length ? studies?.studyFilter?.nameAlias.includes(port.nameAlias) : true) &&
          (studies?.studyFilter?.studyCodeAlias?.length ? studies?.studyFilter.studyCodeAlias?.includes(port.studyCodeAlias) : true) &&
          (studies?.studyFilter?.isActive?.length ? studies?.studyFilter.isActive?.includes(port.isActive) : true)
        ) {
          return true;
        } else {
          return false;
        }
      });
      setfilteredData(filterResult);
    } else {
      setfilteredData(studies?.allStudies);
    }
  }, [JSON.stringify(studies?.studyFilter)]);

  useEffect(() => {
    if (Object.keys(casePrefixes?.casePrefixFilter || {}).length) {
      const filterResult = casePrefixes?.allCasePrefixes?.filter((prefix) =>
        (casePrefixes?.casePrefixFilter?.name?.length ? casePrefixes?.casePrefixFilter?.name.includes(prefix.name) : true) &&
        (casePrefixes?.casePrefixFilter?.documentTemplate?.length 
          ? casePrefixes?.casePrefixFilter?.documentTemplate.includes(prefix.documentTemplate?.name) 
          : true)
      );
      setFilteredCasePrefixes(filterResult);
    } else {
      setFilteredCasePrefixes(casePrefixes?.allCasePrefixes);
    }
  }, [JSON.stringify(casePrefixes?.casePrefixFilter)]);
  

  if (!common?.permissions?.['Study Management']?.read) {
    return <NoPermissionAlert />;
  }


  return (
    <>
      <div className="flex gap-[10px] justify-between items-end">
        <HemaHeadingWithSubText heading="Study Management" sub="Manage your studies here." />
        <div className="flex gap-[10px] ">
          {common?.permissions?.['Study Management']?.write && (
            <Button
              text="Create New Study"
              icon={<Add color="white" />}
              color="text-white"
              bg="bg-primary1"
              cta={() => {
                setformName('Create Study');
                createNewStudy[0].options = sponsers?.allSponsers?.filter((sponsor) => sponsor.isActive === true);
                createNewStudy[0].disabled = false;
                createNewStudy[1].initialValue = '';
                createNewStudy[2].initialValue = '';
                createNewStudy[3].initialValue = '';
                createNewStudy[4].initialValue = '';
                createNewStudy[7].initialValue = [];
                createNewStudy[7].options= casePrefixes.allCasePrefixes;
                createNewStudy[8].initialValue = [];
                // setFormIcon(<CreateSite />);
                setokBtnText('Create');
                setokBtnIcon(<Add color="white" />);
                setUpdatedData(createNewStudy?.filter((item) => item.name !== 'changeReason'));
                addStudyCTA();
                dispatch(setForm({ state: true, type: 'create-Study' }));
              }}
            />
          )}
        </div>
      </div>

      <Alert />
      <div className="bg-white rounded-[5px] pt-[16px] pb-[10px] mt-[27px] mb-[13px] inventory-tabs">
        <>
          <div className="mb-[16px] pl-[16px]">
            <FormSearch w="w-[400px]" searchQuery={searchQuery} setsearchQuery={setsearchQuery} />
          </div>

          {studies?.allStudies ? (
            filteredData?.length > 0 || Object.keys(studies?.studyFilter)?.length ? (
              <DataTable
                customStyles={{
                  responsiveWrapper: {
                    style: { overflow: 'visible !important' },
                  },
                }}
                noDataComponent={<NoDataComponent setActiveFilterTab={setfilteredData} activeFilterOriginalData={studies?.allStudies} resetRedux={setStudyFilter} />}
                data={filteredData}
                className="hdxa-rdt-filter border-t-[1px] border-solid border-[#DEE2E6] managment-table-st"
                columns={[
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Sponsor Name'} color="text-[#000000]" className="text-xs font-normal whitespace-pre" />
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="sponsor"
                            setRedux={setStudyFilter}
                            reduxValues={studies?.studyFilter || []}
                            options={Array.from(new Set(studies?.allStudies.map((filter) => filter.sponsor?.name)))}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.sponsor?.name} />,
                    sortId: 'sponsor.name',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Study Name'} color="text-[#000000]" className="text-xs font-normal" />{' '}
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="name"
                            setRedux={setStudyFilter}
                            reduxValues={studies?.studyFilter || []}
                            options={Array.from(new Set(studies?.allStudies.map((filter) => filter?.name)))}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.name} />,
                    sortId: 'name',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Study Code'} color="text-[#000000]" className="text-xs font-normal" />{' '}
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="studyCode"
                            setRedux={setStudyFilter}
                            reduxValues={studies?.studyFilter || []}
                            options={Array.from(new Set(studies?.allStudies.map((filter) => filter?.studyCode)))}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.studyCode} />,
                    sortId: 'studyCode',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Study Name Alias'} color="text-[#000000]" className="text-xs font-normal whitespace-pre" />
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="nameAlias"
                            setRedux={setStudyFilter}
                            reduxValues={studies?.studyFilter || []}
                            options={Array.from(new Set(studies?.allStudies.map((filter) => filter?.nameAlias)))}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.nameAlias} />,
                    sortId: 'nameAlias',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Study Code Alias'} color="text-[#000000]" className="text-xs font-normal whitespace-pre" />
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="studyCodeAlias"
                            setRedux={setStudyFilter}
                            reduxValues={studies?.studyFilter || []}
                            options={Array.from(new Set(studies?.allStudies.map((filter) => filter?.studyCodeAlias)))}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.studyCodeAlias} />,
                    sortId: 'studyCodeAlias',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Active'} color="text-[#000000]" className="text-xs font-normal" />{' '}
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="isActive"
                            setRedux={setStudyFilter}
                            reduxValues={studies?.studyFilter || []}
                            options={Array.from(new Set(studies?.allStudies.map((filter) => filter?.isActive)))}
                            type="boolean"
                            boolTrueText="Active"
                            boolFalseText="In-Active"
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.isActive ? 'Yes' : 'No'} />,
                    sortId: 'isActive',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <HemaValue text="Actions" color="text-[#000000]" className="text-xs font-normal" />
                        </div>
                      </div>
                    ),
                    selector: (row, index) => (
                      <div className="flex-grow four">
                        <div className="flex justify-end gap-[5px] meta">
                          {common?.permissions?.['Study Management']?.update && (
                            <Button
                              icon={<Edit color="#775FD5" />}
                              color="text-white"
                              bg="bg-bgActionDots"
                              cta={() => {
                                createNewStudy[0].initialValue = row?.sponsor?.id;
                                createNewStudy[0].placeholder = row?.sponsor?.name;
                                createNewStudy[0].disabled = true;
                                createNewStudy[1].initialValue = row?.name;
                                createNewStudy[2].initialValue = row?.studyCode;
                                createNewStudy[3].initialValue = row?.nameAlias;
                                createNewStudy[4].initialValue = row?.studyCodeAlias;
                                createNewStudy[5].initialValue = row?.isActive;
                                createNewStudy[6].initialValue = row?.logisticOrchestratorStatus;
                                createNewStudy[7].initialValue =  row?.casePrefixes;
                                createNewStudy[7].options =  casePrefixes.allCasePrefixes;
                                createNewStudy[8].initialValue =  row?.timePoints?.map(obj => obj.name);
                                setUpdatedData(createNewStudy);
                                setformName('Edit Study');
                                // setFormIcon(<ExpiryAlertPurple />);
                                dispatch(editFormReducer(row));
                                setokBtnText('Save');
                                setokBtnIcon(<Confirm />);
                                addStudyCTA(row?.id);
                                dispatch(setForm({ state: true, type: 'edit-study' }));
                              }}
                            />
                          )}
                          {/* <Button icon={<SearchColor />} color="text-white" bg="bg-bgActionDots" cta={() => {}} /> */}
                        </div>
                      </div>
                    ),
                  },
                ]}
                pagination
                onSort={(row, direction, sorted) => {
                  setfilteredData(sortedData(row.sortId, direction, sorted)?.filter((data) => Object.keys(data)?.length));
                }}
                paginationComponent={(e) => {
                  return <Pagination e={e} />;
                }}
              />
            ) : (
              <BootstrapAlert variant="warning" className="mt-3 text-center">
                There are no records to display.
              </BootstrapAlert>
            )
          ) : (
            <>
              <br />
              <Skeleton count={4} />
              <br />
              <Skeleton count={4} />
              <br />
              <Skeleton count={4} />
            </>
          )}
        </>
      </div>

      {uisettings?.openform && (
        <FormContainer
          cta={CTA}
          formType={updatedData}
          formName={formName}
          icon={formIcon}
          formValidation={formValidation}
          okBtnIcon={okBtnIcon}
          okBtnText={okBtnText}
          setokBtnIcon={setokBtnIcon}
          setokBtnText={setokBtnText}
        />
      )}
<div className="flex gap-[10px] justify-between items-end mt-8">
        <HemaHeadingWithSubText heading="Case Prefix Management" sub="Manage your case prefixes here." />
        <div className="flex gap-[10px]">
          {common?.permissions?.['Study Management']?.write && (
           <Button
  text="Create New Case Prefix"
  icon={<Add color="white" />}
  color="text-white"
  bg="bg-primary1"
  cta={() => {
    setformName('Create Case Prefix');

    createNewCasePrefix[0].initialValue = '';
    createNewCasePrefix[1].options = documentTemplates;

    setUpdatedData(createNewCasePrefix?.filter((item) => item.name !== 'changeReason'));

    addCasePrefixCTA();

    setokBtnText('Create');
    setokBtnIcon(<Add color="white" />);
    dispatch(setForm({ state: true, type: 'create-case-prefix' }));
  }}
/>





          )}
        </div>
      </div>

      {/* Case Prefix Table */}
      <Alert />
      <div className="bg-white rounded-[5px] pt-[16px] pb-[10px] mt-[27px] mb-[13px] inventory-tabs">
          <>
        <div className="mb-[16px] pl-[16px]">
            <FormSearch w="w-[400px]" searchQuery={searchCasePrefixQuery} setsearchQuery={setSearchCasePrefixQuery} />
          </div>

        {casePrefixes?.allCasePrefixes ? (
          filteredCasePrefixes?.length > 0 ? (
            <DataTable
              customStyles={{
                responsiveWrapper: {
                  style: { overflow: 'visible !important' },
                },
              }}
              noDataComponent={<NoDataComponent setActiveFilterTab={setFilteredCasePrefixes} activeFilterOriginalData={casePrefixes?.allCasePrefixes}  />}
              data={filteredCasePrefixes}
              className="hdxa-rdt-filter border-t-[1px] border-solid border-[#DEE2E6] managment-table-st"
              columns={[
                {
                  name: (
                    <div className="w-full">
                      <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                        <HemaValue text={'Case Prefix Name'} color="text-[#000000]" className="text-xs font-normal" />
                        <span className="short-icon">
                          <TableSortIcon />
                        </span>
                      </div>
                      <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                        <FilterColumn
                          columnName="casePrefix"
                          setRedux={setCasePrefixFilter}
                          reduxValues={casePrefixes?.casePrefixFilter || []}
                          options={Array.from(new Set(casePrefixes?.allCasePrefixes.map((filter) => filter?.name)))}
                        />
                      </div>
                    </div>
                  ),
                  sortable: true,
                  selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.name} />,
                  sortId: 'casePrefix',
                },
                {
                  name: (
                    <div className="w-full">
                      <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                        <HemaValue text={'Document Template'} color="text-[#000000]" className="text-xs font-normal" />
                        <span className="short-icon">
                          <TableSortIcon />
                        </span>
                      </div>
                      <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                        <FilterColumn
                          columnName="documentTemplate"
                          setRedux={setCasePrefixFilter}
                          reduxValues={casePrefixes?.documentTemplateFilter || []}
                          options={Array.from(new Set(casePrefixes?.allCasePrefixes.map((filter) => filter?.documentTemplate?.name)))}
                        />
                      </div>
                    </div>
                  ),
                  selector: (row) => row?.documentTemplateName || "No Template",
                  sortable: true,
                },
                {
  name: "Actions",
  selector: (row) => (
    <div className="flex gap-2">
      <Button
        icon={<Edit color="#775FD5" />}
        color="text-white"
        bg="bg-bgActionDots"
        cta={() => {
          setformName("Edit Case Prefix");

          createNewCasePrefix[0].initialValue = row?.name || "";
          createNewCasePrefix[1].options = documentTemplates;
          createNewCasePrefix[1].initialValue = row?.documentTemplateId || "";

          setUpdatedData([...createNewCasePrefix]);

          addCasePrefixCTA(row?.id);
          dispatch(setForm({ state: true, type: "edit-case-prefix" }));
        }}
      />
      <Button
        icon={<Delete color="red" />}
        color="text-white"
        bg="bg-red-500"
        cta={() => handleDeleteClick(row?.id)}
      />
    </div>
  ),
}

              ]}
              pagination
              onSort={(column, sortDirection) => setFilteredCasePrefixes([...filteredCasePrefixes].sort((a, b) => (a[column.sortId] > b[column.sortId] ? (sortDirection === 'asc' ? 1 : -1) : (sortDirection === 'asc' ? -1 : 1))))}
              paginationComponent={(e) => {
                return <Pagination e={e} />;
              }}
            />
          ) : (
            <BootstrapAlert variant="warning" className="mt-3 text-center">
              No case prefixes found.
            </BootstrapAlert>
          )
        ) : (
          <Skeleton count={4} />
        )}
        </>
      </div>
      {uisettings?.openform && (
        <FormContainer
          cta={CTA}
          formType={updatedData}
          formName={formName}
          icon={formIcon}
          formValidation={formValidation}
          okBtnIcon={okBtnIcon}
          okBtnText={okBtnText}
          setokBtnIcon={setokBtnIcon}
          setokBtnText={setokBtnText}
        />
      )}
      {/* Delete Confirmation Modal */}
{deleteModalOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
      <h2 className="text-lg font-semibold mb-4">Confirm Delete</h2>
      <p className="mb-2">Please provide a reason for deleting this case prefix:</p>
      
      <input
        type="text"
        value={deleteReason}
        onChange={(e) => setDeleteReason(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded"
        placeholder="Enter reason..."
      />

      <div className="flex justify-end gap-4 mt-4">
  <button
    onClick={() => {
      setDeleteModalOpen(false);
      setDeleteReason("");
    }}
  >
    Cancel
  </button>

  <button
  onClick={async () => {
    await deleteCasePrefixAction(deleteCasePrefixId, deleteReason);
    setDeleteModalOpen(false);
    setDeleteReason("");
  }}
  disabled={!deleteReason.trim()}
>
  Delete
</button>

</div>

    </div>
  </div>
)}


    </>
  );
};

export default StudyManagement;
