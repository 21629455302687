import React from 'react';

export function MedicalSample(props) {
    return (
      <svg
      width="24"
      height="24"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="3.5"
          y1="12.0004"
          x2="20.5"
          y2="12.0004"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#775FD5" />
          <stop offset="1" stopColor="#F03A85" />
        </linearGradient>
      </defs>
      <g fill="url(#paint0_linear)" fillRule="evenodd" clipRule="evenodd">
        <path d="M18.142 4L4 18.142l7.778 7.778L25.92 11.778zm-1.328 4.35l-1.415 1.415l4.95 4.95l1.414-1.415zm-3.536 3.536L11.864 13.3l4.95 4.95l1.414-1.414zm-3.536 3.536l-1.414 1.414l4.95 4.95l1.414-1.415z"></path>
        <path d="m11.864 23.2l11.314-11.314l17.677 17.678a8 8 0 1 1-11.313 11.313zm2.828 0l16.264 16.263a6 6 0 0 0 8.485-8.485L23.178 14.714z"></path>
        <path d="m19.642 23.907l7.779-7.778l12.02 12.02l-7.778 7.779zm2.829 0l9.192 9.192l4.95-4.95l-9.192-9.192z"></path>
        <path d="M27.066 29.917c-1.414.707-3.182 2.475-3.536 3.536l6.718 6.717a7 7 0 0 0 9.9-9.899l-2.122-2.12l-6.363 6.363z"></path>
        <path d="m24.755 33.263l6.2 6.2a6 6 0 0 0 8.486-8.485l-1.415-1.414l-6.363 6.364l-4.755-4.755a9.3 9.3 0 0 0-1.3 1.087a7.3 7.3 0 0 0-.853 1.003m-.562-2.418c.712-.712 1.593-1.406 2.426-1.822l.12-.06l.656-.132l4.268 4.268l6.363-6.363l2.83 2.828a8 8 0 1 1-11.314 11.313l-7.072-7.07l.083-.585l.029-.085c.253-.761.924-1.605 1.611-2.292"></path>
      </g>
    </svg>
    

    )
  }
  