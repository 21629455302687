import { post, get, put, remove } from './http';
import { errorHandling } from './error';

const getPermissions = async (userId) => {
    try {
      return await get(`user/portal-users/${userId}/permissions`);
    } catch (e) {
      console.log(e);
    }
};

const savePermissions = async (data) => {
  try {
    return await post(`user/portal-users/permission`, data);
  } catch (e) {
    console.log(e);
  }
};

export { getPermissions, savePermissions };