import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { HemaLabel, FormText, Button } from "../../../utils";
import { createNewPINAction, verifyUserPINAction } from "../../../Actions/userPin";
import { setUserDetails } from '../../../Store/reducers/common';
import { showSuccessReducer } from "../../../Store/reducers/uiSettings";
import './pinModalStyle.css';

const PinModal = ({ isOpen, onRequestClose, title }) => {
  // Local state to control whether the PIN form is shown/edited.
  // If userDetails.pin is "set", the Change PIN button toggles editing mode.
  const [isEditing, setIsEditing] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onRequestClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onRequestClose]);

  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.common.user);

  if (!isOpen) return null;

  const showForm = (userDetails?.pin !== "set") || isEditing;

  const initialValues = {
    ...(userDetails?.pin === "set" && isEditing ? { currentPin: '' } : {}),
    createPin: '',
    confirmPin: ''
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {title && <h2 className="modal-title">{title}</h2>}
        <div className="modal-body">
          {successMessage && (
            <div style={{ color: 'green', padding: '10px', marginBottom: '15px', fontSize: '14px' }}>
              {successMessage}
            </div>
          )}
          {showForm ? (
            <Formik
              initialValues={initialValues}
              validate={(values) => {
                const pinRegex = /^\d{6}$/; // Ensures exactly 6 digits
                const errors = {};
                // When changing PIN, require the current PIN.
                if (userDetails?.pin === "set" && isEditing) {
                  if (!values.currentPin) {
                    errors.currentPin = 'Required';
                  } else if (!pinRegex.test(values.currentPin)) {
                    errors.currentPin = 'PIN must consist of digits only and be exactly 6 characters long';
                  }
                }

                if (!values.createPin) {
                  errors.createPin = 'Required';
                }else if (!pinRegex.test(values.createPin)) {
                  errors.createPin = 'PIN must consist of digits only and be exactly 6 characters long';
                }

                if (!values.confirmPin) {
                  errors.confirmPin = 'Required';
                } else if (!pinRegex.test(values.confirmPin)) {
                  errors.confirmPin = 'PIN must consist of digits only and be exactly 6 characters long';
                } else if (values.createPin !== values.confirmPin) {
                  errors.confirmPin = 'PINs do not match';
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting, resetForm, setFieldError }) => {
                // If changing PIN, verify the current PIN first.
                if (userDetails?.pin === "set" && isEditing) {
                  const verifyResult = await verifyUserPINAction({ pin: values.currentPin });

                  if (verifyResult.status !== 200) {
                    if (verifyResult.message == "Incorrect PIN.") {
                      setFieldError("currentPin", "Incorrect PIN");
                    } else {
                      setFieldError("currentPin", "Error");
                    };
                    setSubmitting(false);
                    return;
                  }
                }
                const result = await createNewPINAction({ pin: values.createPin });
                setSubmitting(false);
                if (result.status === 200) {
                  setIsEditing(false);
                  resetForm();
                  setSuccessMessage("PIN was created successfully.");
                  //onRequestClose();
                  dispatch(setUserDetails({ ...userDetails, pin: "set" }));
                  setTimeout(() => setSuccessMessage(""), 5000);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} className="pr-4">
                  <div className="flex flex-col gap-[15px]">
                    {/* Only show Current PIN field when editing (i.e. changing PIN) */}
                    {userDetails?.pin === "set" && isEditing && (
                      <div className="flex-grow">
                        <HemaLabel
                          text="Enter Current PIN"
                          required={true}
                          textSize="text-[13px]"
                          color="#454545"
                          className="mb-[10px]"
                        />
                        <FormText
                          type="password"
                          placeholder="Enter your current PIN"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="currentPin"
                          value={values.currentPin || ""}
                          rows={1}
                          maxLength={6}
                          minLength={6} 
                          pattern="\d{6}" 
                        />
                        {errors.currentPin && touched.currentPin && (
                          <div className="error text-[red] text-[12px] pt-[2px]">
                            {errors.currentPin}
                          </div>
                        )}
                      </div>
                    )}
                    <div className="flex-grow">
                      <HemaLabel
                        text="Create a 6-Digit PIN"
                        required={true}
                        textSize="text-[13px]"
                        color="#454545"
                        className="mb-[10px]"
                      />
                      <FormText
                        type="password"
                        placeholder="Enter your new PIN"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="createPin"
                        value={values.createPin || ""}
                        rows={1}
                        maxLength={6}
                        minLength={6} 
                        pattern="\d{6}"
                      />
                      {errors.createPin && touched.createPin && (
                        <div className="error text-[red] text-[12px] pt-[2px]">
                          {errors.createPin}
                        </div>
                      )}
                    </div>
                    <div className="flex-grow">
                      <HemaLabel
                        text="Confirm New PIN"
                        required={true}
                        textSize="text-[13px]"
                        color="#454545"
                        className="mb-[10px]"
                      />
                      <FormText
                        type="password"
                        placeholder="Confirm your new PIN"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="confirmPin"
                        value={values.confirmPin || ""}
                        rows={1}
                        maxLength={6}
                        minLength={6} 
                        pattern="\d{6}" 
                      />
                      {errors.confirmPin && touched.confirmPin && (
                        <div className="error text-[red] text-[12px] pt-[2px]">
                          {errors.confirmPin}
                        </div>
                      )}
                    </div>
                    <div className="modal-actions flex flex-row gap-[10px]">
                      <Button
                        type="button"
                        text="Save PIN"
                        bg="bg-primary1"
                        color="text-white"
                        disabled={
                          !values.createPin.trim() ||
                          !values.confirmPin.trim() ||
                          (userDetails?.pin === "set" && isEditing && !values.currentPin.trim()) ||
                          Boolean(errors.createPin) ||
                          Boolean(errors.confirmPin) ||
                          (userDetails?.pin === "set" && isEditing && !!errors.currentPin)
                        }
                        cta={async () => {
                          handleSubmit();
                        }}
                      />
                      {userDetails?.pin === "set" ? (
                        <Button
                          type="button"
                          text="Cancel"
                          bg="bg-secondary"
                          color="text-white"
                          cta={() => setIsEditing(false)}
                        />
                      ) : (
                        <Button
                          type="button"
                          text="Close"
                          bg="bg-primary1"
                          color="text-white"
                          cta={() => onRequestClose()}
                        />
                      )}
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          ) : (
            <div className="flex flex-row gap-[15px] justify-center">
              <Button
                type="button"
                text="Change PIN"
                bg="bg-primary1"
                color="text-white"
                cta={() => setIsEditing(true)}
              />
              <Button
                type="button"
                text="Close"
                bg="bg-primary1"
                color="text-white"
                cta={() => onRequestClose()}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PinModal;
