
import React, { useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {useSelector} from 'react-redux'
import ModalCom from '../../components/Modal';
import hematogenix from '../../assets/images/Hematogenix.svg';

import PinModal from './UserPin/pinModal';

import { Logout, Password } from '../../HemeIconLibrary';

const HemaNavbar = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const [pinModalShow, setPinModalShow] = React.useState(false); 

  const navigate = useNavigate();
  const {common} = useSelector(state=>state)
  useEffect(()=>{
   if(common.showRefreshToken) {
    setModalShow(true)
   } else {
    setModalShow(false)
   }
  },[common.showRefreshToken])
  return (
    <div className="h-[70px] hema-navbar fixed w-full top-0 left-0 z-[444]">
      <Navbar expand="lg">
        <Container fluid>
          <Navbar.Brand href="#">
            <img src={hematogenix} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="my-2 me-auto my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
              <div className="items-center d-flex">
                {/* <div className="d-flex mr-[20px]">
                  <p className="font-semibold text-white text-[13px] mb-0 mr-[11px]">English</p>
                  <img src={flag} alt="" width="25" />
                </div> */}
                {/* <Nav.Link className="d-flex justify-center items-center mr-[20px] bg-white rounded-[90px] relative w-[36px] h-[36px]">
                  <Message />
                  <p className=" absolute right-[0px] top-[0px] bg-tableOrangeColor rounded-[90px]  d-flex  justify-center items-center w-[16px] h-[16px] font-medium text-white text-[12px]">
                    5
                  </p>
                </Nav.Link>
                <Nav.Link className="d-flex justify-center items-center mr-[20px] bg-white rounded-[90px] relative w-[36px] h-[36px]">
                  <Notification />
                  <p className=" absolute right-[-0px] top-[0px] bg-tableOrangeColor rounded-[90px]  d-flex  justify-center items-center w-[16px] h-[16px] font-medium text-white text-[12px]">
                    5
                  </p>
                </Nav.Link> */}
                <NavDropdown
                  className="items-center justify-center p-0 rounded-full d-flex w-9 h-9 user-dropdown"
                  title={
                    <div className="flex items-center justify-center  p-0 capitalize bg-white rounded-[50%] w-[40px] h-[40px] bold">
                      {localStorage.getItem('hema-token-first')?.[0]}
                      {localStorage.getItem('hema-token-last')?.[0]}
                    </div>
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item
                    className="user-down-item"
                    href=""
                    onClick={() => {
                      localStorage.removeItem('hema-token');
                      localStorage.removeItem('hema-token-refresh');
                      navigate('/login');
                    }}
                  >
                    <div className="flex items-center w-auto gap-2 ">
                      <Logout />
                      <span className="text-base font-semibold">Logout</span>
                    </div>
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    className="user-down-item"
                    href=""
                    onClick={(payload) => {
                      setPinModalShow(true);
                    }}
                  >
                    <div className="flex items-center w-auto gap-2">
                      <Password />
                      <span className="text-base font-semibold">User Pin</span>
                    </div>
                  </NavDropdown.Item>

                  {/* <NavDropdown.Item className="user-down-item" href="" onClick={() => setModalShow(true)}>
                    <div className="flex items-center w-auto gap-2 ">
                      <Logout />
                      <span className="text-base font-semibold">Timer</span>
                    </div>
                  </NavDropdown.Item> */}
                </NavDropdown>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <PinModal
        isOpen={pinModalShow}
        onRequestClose={() => setPinModalShow(false)}
        title="User Pin"
      />

      <ModalCom show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};
export default HemaNavbar;
