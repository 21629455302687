import { post, get, put, remove } from './http';
import { errorHandling } from './error';

const getAllCasePrefixes = async (data) => {
    try {
        return await get(`study/caseprefixes`, data);
    } catch (e) {
        console.log(e);
    }
};

const createCasePrefix = async (data) => {
    try {
        const response = await post('study/caseprefixes', data);
        return response?.data;
    } catch (e) {
        console.error("Error creating Case Prefix:", e?.response?.data || e.message);
        throw new Error('Error creating case prefix');
    }
};


const updateCasePrefix = async (casePrefixId, data) => {
    try {
        return await put(`study/caseprefixes/${casePrefixId}`, data);
    } catch (e) {
        console.log(e);
        throw new Error('Error updating case prefix');
    }
};

const deleteCasePrefix = async (casePrefixId, changeReason) => {
    try {
        return await remove(`study/caseprefixes/${casePrefixId}?changeReason=${encodeURIComponent(changeReason)}`);
    } catch (e) {
        throw new Error("Error deleting case prefix");
    }
};


const getAllDocumentTemplates = async () => {
    try {
        return await get(`order/document-templates`);
    } catch (err) {
        return err?.response?.data;
    }
};

export { 
    getAllCasePrefixes, 
    createCasePrefix, 
    updateCasePrefix,
    deleteCasePrefix,
    getAllDocumentTemplates 
};
