import { 
  getAllCasePrefixes, 
  createCasePrefix, 
  updateCasePrefix,
  deleteCasePrefix,
  getAllDocumentTemplates 
} from "../Services/casePrefix";

import { store } from "../Store";
import {
  setAllCasePrefixesReducer,
  setAllDocumentTemplatesReducer,
} from "../Store/reducers/casePrefix";
import {showSuccessReducer } from '../Store/reducers/uiSettings';

import { caseInsensitiveSort } from '../helpers/commonHelper';

const getAllCasePrefixesAction = async () => {
    try {
        const result = await getAllCasePrefixes();
        
        if (result?.data) {
            store.dispatch(setAllCasePrefixesReducer([...result.data])); 
        } else {
            console.warn("No case prefixes found or API returned null.");
        }
    } catch (e) {
        console.error("Error Dispatching Case Prefixes to Redux:", e);
    }
};

const createCasePrefixAction = async (data) => {
  try {
    const response = await createCasePrefix(data);
    return response; 
  } catch (e) {
    console.error("Error creating Case Prefix:", e?.response?.data || e.message);
  }
};

const updateCasePrefixAction = async (casePrefixId, data) => {
  try {
    const response = await updateCasePrefix(casePrefixId, data);
    return response;
  } catch (e) {
    console.error("Error updating Case Prefix:", e?.response?.data || e.message);
  }
};
const deleteCasePrefixAction = async (casePrefixId, changeReason) => {
    try {
        const result = await deleteCasePrefix(casePrefixId, changeReason);

        if (result?.status === 200) {
            store.dispatch(showSuccessReducer("Case Prefix deleted successfully."));
            store.dispatch(getAllCasePrefixesAction()); 
        } else {
            console.error("Delete failed, unexpected response:", result);
        }
    } catch (e) {
        console.error("Error deleting case prefix:", e);
    }
};


const getAllDocumentTemplatesAction = async () => {
  try {
    const result = await getAllDocumentTemplates();   

    if (result?.data && result.data.length > 0) {
      store.dispatch(setAllDocumentTemplatesReducer(result.data.sort(caseInsensitiveSort)));
    } else {
      console.warn("No document templates received from API.");
    }
  } catch (e) {
    console.error("Error fetching document templates:", e);
  }
};

export { 
  getAllCasePrefixesAction, 
  createCasePrefixAction, 
  updateCasePrefixAction,
  deleteCasePrefixAction,
  getAllDocumentTemplatesAction 
};
