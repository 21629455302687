import {
  Address,
  Barcode,
  Country,
  LabelCategory,
  LabelName,
  Location,
  Recipient,
  StudyName,
} from "../../../HemeIconLibrary";

export const DeclarationOfConformity = [
  {
    label: "",
    initialValue: "Fill the details",
    fluid: true,
    type: "heading-only",
  },
  {
    label: "Study/Protocol Name",
    icon: <StudyName />,
    type: "text",
    initialValue: "",
    placeholder: "Protocol Name",
    fluid: true,
    name: "name",
  },
];
