/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allCases: null,
  case: null,
  sponsorFilter: {},
  sponerStudy:null,
  allSitesForCase:null
};

export const caseItem = createSlice({
  name: 'caseItems',
  initialState,
  reducers: {

    addNewItemsReducer: (state, action) => {
      if (state.allCases) {
        state.allCases = [action.payload, ...state.allCases];
      } else {
        state.allCases = [action.payload];
      }
    },
    editCaseReducer: (state, action) => {
      state.allCases = state.allCases.map((caseItem) => {
        if (caseItem.id === action.payload.id) {
          return action.payload.data;
        } else {
          return caseItem;
        }
      });
    },
  },
});

export const { addNewItemsReducer, editCaseReducer } = caseItem.actions;

export default caseItem.reducer;
