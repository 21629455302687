import { jsPDF } from 'jspdf';
import bwipjs from 'bwip-js';
import { getCaseByAccessionNumberAction } from '../../Actions/case';

const formatDate = (dateStr) => {
  if (!dateStr) return "";
  const d = new Date(dateStr);
  if (isNaN(d)) return dateStr;
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const day = d.getDate().toString().padStart(2, '0');
  const year = d.getFullYear();
  return `${month}/${day}/${year}`;
};

export const printLabel = async (row) => {
  const quantity = Number(row.quantity) || 1;
  const accessionNumber = row.accessionNumber || "";

  const caseData = await getCaseByAccessionNumberAction(accessionNumber);

  const subjectNumber = caseData?.data?.subjectNumber || "";
  const dateOfBirth = caseData?.data?.dateOfBirth || "";
  const formattedDOB = formatDate(dateOfBirth);
  const studyNumber = caseData?.data?.studyCode || "";
  const sponsorAbbreviation = caseData?.data?.sponsorAbbreviation || "";
  const sexAbbreviation = caseData?.data?.sex === 'Male' ? 'M' : caseData?.data?.sex === 'Female' ? 'F' : '';

  // Data Matrix content text
  const dataMatrixText = `NPP,${accessionNumber},${subjectNumber},${formattedDOB},${studyNumber},${sponsorAbbreviation}`;

  // Create a canvas element for bwip-js to render the Data Matrix code
  const canvas = document.createElement('canvas');
  try {
    bwipjs.toCanvas(canvas, {
      bcid: 'datamatrix',       // Barcode type: Data Matrix
      text: dataMatrixText,
      scale: 8, 
      includetext: false,
    });
  } catch (e) {
    console.error('Error generating Data Matrix code', e);
    return;
  }
  const dataMatrixDataUrl = canvas.toDataURL();

  // Create a new PDF document
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'in',
    format: [0.958, 0.85], // Label size
  });

  for (let i = 0; i < quantity; i++) {
    if (i > 0) {
      doc.addPage();
    }

    doc.setFont('helvetica', 'normal'); 
    doc.setFontSize(7); 

    // Draw Data Matrix Code instead of QR code
    doc.addImage(dataMatrixDataUrl, 'PNG', 0.05, 0.08, 0.30, 0.30); // x, y, width, height

    // Add "Hematogenix"
    doc.setFontSize(6); 
    doc.text("Hematogenix", 0.39, 0.2);

    // Add Label Fields
    doc.setFontSize(7); 
    doc.text(accessionNumber, 0.05, 0.5);
    doc.text(subjectNumber, 0.05, 0.6);
    doc.text(`DOB: ${formattedDOB}`, 0.05, 0.7);
    doc.text(`Sex: ${sexAbbreviation}`, 0.05, 0.8);
  }

  doc.save(accessionNumber + ' labels.pdf');
};
