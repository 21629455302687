import { post, get, put, remove } from './http';
import { errorHandling } from './error';

const createNewSubject = async (data) => {
  try {
    return await post(`study/subjects`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
  }
};
const getStudySubjects = async (studyId, data) => {
  try {
    return await get(`study/studies/${studyId}/subjects`, data);
  } catch (e) {
    console.log(e);
  }
};
const editSubject = async (id, data) => {
  try {
    return await put(`study/subjects/${id}`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
  }
};

export { createNewSubject, getStudySubjects, editSubject, };
