import { getCaseBasicInfoByAccessionNumber, createNewSample, signOffCaseReport, getAllCasesByStudyAndSite, getCaseByAccessionNumber, createNewCase, getNextAccessionNumber, updateCaseResult, getCaseReportTemplate } from "../Services/case";
import { store } from "../Store";
import { caseInsensitiveSort } from '../helpers/commonHelper';

const getAllCasesAction = async (data) => {
  // const result = await getAllStudies(data);
  // store.dispatch(setAllStudiesReducer(result?.data.sort(caseInsensitiveSort)));
};
const createNewCaseAction = async (data) => {
  const result = await createNewCase(data);
  return result;
};

const createNewSampleAction = async (data) => {
  const result = await createNewSample(data);
  return result;
};

const updateCaseResultAction = async (id, data) => {
  const result = await updateCaseResult(id, data);
  return result;
};

const getNextAccessionNumberAction = async (casePrefixId) => {
  const result = await getNextAccessionNumber(casePrefixId);
  return result;
};

const  getAllCasesByStudyAndSiteAction = async (studyId, siteId) => {
  const result = await getAllCasesByStudyAndSite(studyId, siteId);
  if (result && result.status === 200) {
    return result;
  }
};

const getCaseByAccessionNumberAction = async (accessionNumber) => {
  const result = await getCaseByAccessionNumber(accessionNumber);
  if (result && result.status === 200) {
    return result;
  }
};

const getCaseBasicInfoByAccessionNumberAction = async (accessionNumber) => {
  const result = await getCaseBasicInfoByAccessionNumber(accessionNumber);
  if (result && result.status === 200) {
    return result;
  }
};

const getCaseReportTemplateAction = async (caseId) => {
  const result = await getCaseReportTemplate(caseId);
  if (result && result.status === 200) {
    return result;
  }
};

const signOffCaseReportAction = async (caseId, data) => {
  const result = await signOffCaseReport(caseId, data);
  if (result && result.status === 200) {
    return result;
  }
  return null
};


export { getCaseBasicInfoByAccessionNumberAction, createNewSampleAction, getAllCasesByStudyAndSiteAction, getCaseByAccessionNumberAction, getAllCasesAction, createNewCaseAction, updateCaseResultAction, getNextAccessionNumberAction, getCaseReportTemplateAction, signOffCaseReportAction };
