import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';

import { printLabel } from './printLabel';

import HemaHeadingWithSubText from '../../utils/HemaHeadingWithSubText';
import { FormSearch, Alert, Pagination, HemaValue, FilterColumn, NoPermissionAlert, NoDataComponent, HemaLabel, Button, SingleSelect, FormText, } from '../../utils';
import { FirstName, Barcode, LabelDate, CrossIcon, Add, Edit, Confirm, TableSortIcon, PrintIcon } from '../../HemeIconLibrary';

import { createNewSubjectAction, getStudySubjectsAction, updateSubjectAction } from '../../Actions/subject';
import { getSitesByStudyIdAction } from '../../Actions/siteManagment';
import {
  getAllSponserAction,
  getAllSponserStudiesAction,
} from "../../Actions/sponsers";
import './case.scss';
import { getAllCasesByStudyAndSiteAction } from '../../Actions/case';
import Select from 'react-select';


const Cases = () => {
  const dispatch = useDispatch();
   const navigate = useNavigate();
  const { uisettings, sponsers, selectedStudySubjects, common, sites, caseItem } = useSelector((state) => state);

  /* form states */
  const [sponsorList, setSponsorList] = useState([]);
  const [studyList, setStudyList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [study, setStudy] = useState();
  const [sponsor, setSponsor] = useState();
  const [site, setSite] = useState();
  const [siteChanged, setSiteChanged] = useState(false);
  const [inputs, setInputs] = useState([]);

  

  //callallEnventoryRequiredAPI
  useEffect(() => {
    (() => {
      getSponsorsEffect();
    })();
  }, []);

  const getSponsorsEffect = async()=> {
    let result = await getAllSponserAction();
    setSponsorList(result.data);
  }

  useEffect(() => {
    (() => {
      sponsorChangedEffect();
    })();
  }, [sponsor]);

  const sponsorChangedEffect = async()=> {
    setStudy(null);
    setSite(null);
    setStudyList([]);
    setSiteList([]);
    setInputs([]);
  }


  const setSponsorFieldValue = async (x, id) => {
    setSponsor(id)
    if (Number.isInteger(id) && id != 0){
      let result = await getAllSponserStudiesAction(id);
      let temp = result.data;
      temp = temp.map(item => ({id: item.id, label: item.name}));
      setStudyList(temp);
    }
  }

  useEffect(() => {
    (async () => {
      studyChangedEffect();
    })();
  }, [study]);

  const studyChangedEffect = async()=> {
    setSite(null);
      setSiteList([]);
      setInputs([]);
      if (study) {
        // getStudySubjectsAction(study.id);
        let result = await getSitesByStudyIdAction(study.id);
        let sites = result?.data ? result?.data : [];
        sites = sites.map(item=> ({id: item.id, label: item.name}));
        setSiteList(sites);
      }
  }

  useEffect(() => {
    (() => {
      siteChangedEffect();
    })();
  }, [site]);

  const siteChangedEffect = async()=> {
    if (study) {
      let result = await getAllCasesByStudyAndSiteAction(study.id, site.id);
      setInputs(result?.data ? result.data : []);
    }
  }



  if (!common?.permissions?.['Study Management']?.read) {
    return <NoPermissionAlert />;
  }
  return (
    <>
      <div className="flex gap-[10px] justify-between items-end">
        <HemaHeadingWithSubText heading="Cases Management" sub="Manage your cases here." />
        <div className="flex gap-[10px] ">
          {common?.permissions?.['Study Management']?.write && (
            <>
              <Button
                text="Add New Case"
                //icon={<Add color="white" />}
                color="text-white"
                bg="bg-primary1"
                cta={() => {
                  navigate('/new-case');
                  console.log(' new case  ')
                  


                }}
              />

              <Button
                text="Manage a case"
                //icon={<Add color="white" />}
                color="text-white"
                bg="bg-primary1"
                cta={() => {
                  navigate('/case-result');
                  console.log(' case manage ')
                  


                }}
              />        

              
            </>
          )}
        </div>
      </div>

     
      <Alert />
      <div className="bg-white rounded-[5px] pt-[16px] pb-[10px] mt-[27px] mb-[13px] inventory-tabs">
        <>
          <div className="bg-white rounded-[5px] pt-[4px] pb-[5px] px-4 mt-[5px] mb-[5px] ">
            <div className={`flex items-start gap-[16px] mb-3`}>
              <div className={`w-full `}>
                <HemaLabel
                  text="Sponsor"
                  className={'mb-[10px]'}
                  required={true}
                  icon={<FirstName />}
                />


                <SingleSelect
                  name={'sponsor'}
                  // onChange={handleChange}
                  setFieldValue={(e, r) => {
                    setSponsorFieldValue(e, r)
                  }
                  }
                  value={sponsor}
                  options={sponsers?.allSponsers?.filter((data) => data.isActive) || []}
                //  formikRef={ref}
                />
                {/* {!sponsor && <div className="error text-[red] text-[12px] pt-[2px]">Required</div>} */}

              </div>

              <div className={`w-full`}>
                <HemaLabel
                  text="Study #"
                  className={'mb-[10px]'}
                  required={true}
                  icon={<Barcode purple />}
                />

                <Select
                  name='study'
                  options={studyList}
                  value={study ? study : null}
                  onChange={(e) => {
                      setStudy(e)
                    }
                  }
                  isClearable
                  isSearchable
                  className="hema-single-select"
                />
                  {/* {siteChanged && !site && <span style={{ color: "red" }}>Required</span>} */}

                {/* <SingleSelect
                  name={'study'}
                  value={study ? study : null}
                  options={sponsers?.sponerStudy?.filter((data) => data?.logisticOrchestratorQcApproved) || []}
                  setFieldValue={(e, r) => {
                    setStudy(r)
                  }
                  }
                /> */}
                {/* {!values.study && <div className="error text-[red] text-[12px] pt-[2px]">Required</div>} */}                
              </div>

              <div className={`w-full`}>
                  <HemaLabel
                      text="Site #"
                      className={'mb-[10px]'}
                      required={true}
                      icon={<Barcode purple />}
                  />

                  <Select
                    name='site'
                    options={siteList}
                    value={site ? site : null}
                    onChange={(e) => {
                      setSite(e)
                      setSiteChanged(true);
                      }
                    }
                    isClearable
                    isSearchable
                    className="hema-single-select"
                  />
            
                  {/* <SingleSelect
                      name={'site'}
                      value={study ? study : null}
                      options={sites?.selectedStudySites || []}
                      setFieldValue={(e, r) => {
                          if (r) {
                              setSite(r)
                              setSiteChanged(true);
                          }
                          else
                              setSite(undefined)
                      }
                      }
                  /> */}
                  {/* {siteChanged && !site && <span style={{ color: "red" }}>Required</span>} */}
              </div>

            </div>



          </div>


        </>
      </div>

      <DataTable
          customStyles={{
              responsiveWrapper: {
                  style: { overflow: 'visible !important' },
              },
          }}
          // noDataComponent={<NoDataComponent setActiveFilterTab={setfilteredData} activeFilterOriginalData={selectedStudySubjects?.allSubjects} resetRedux={setStudyFilter} />}
          data={inputs}
          className="hdxa-rdt-filter border-t-[1px] border-solid border-[#DEE2E6] managment-table-st"
          columns={[

              {
                  name: (
                      <div className="w-full">
                          <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                              <HemaValue text={'Accession Number'} color="text-[#000000]" className="text-xs font-normal" />{' '}
                              <span className="short-icon">
                                  <TableSortIcon />
                              </span>
                          </div>
                          <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                              <FilterColumn
                                  columnName="id"
                                  // setRedux={setStudyFilter}
                                  //  reduxValues={studies?.studyFilter || []}
                                  options={Array.from(new Set(selectedStudySubjects?.allSubjects?.map((filter) => filter?.id)))}
                              />
                          </div>
                      </div>
                  ),
                  sortable: true,
                  selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.accessionNumber} />,
                  sortId: 'accessionNumber',
              },
              {
                  name: (
                      <div className="w-full">
                          <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                              <HemaValue text={'Site'} color="text-[#000000]" className="text-xs font-normal whitespace-pre" />
                              <span className="short-icon">
                                  <TableSortIcon />
                              </span>
                          </div>
                          <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                              <FilterColumn
                                  columnName="site.name"
                                  //  setRedux={setStudyFilter}
                                  //  reduxValues={studies?.studyFilter || []}
                                  options={Array.from(new Set(selectedStudySubjects?.allSubjects?.map((filter) => filter?.siteName)))}
                              />
                          </div>
                      </div>
                  ),
                  sortable: true,
                  selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.siteName} />,
                  sortId: 'site.name',
              },
              {
                  name: (
                      <div className="w-full">
                          <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                              <HemaValue text={'Quantity'} color="text-[#000000]" className="text-xs font-normal whitespace-pre" />
                              <span className="short-icon">
                                  <TableSortIcon />
                              </span>
                          </div>
                          <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                              <FilterColumn
                                  columnName="genderId"
                                  // setRedux={setStudyFilter}
                                  // reduxValues={studies?.studyFilter || []}
                                  options={Array.from(new Set(selectedStudySubjects?.allSubjects?.map((filter) => filter?.GenderId)))}
                              />
                          </div>
                      </div>
                  ),
                  sortable: true,
                  selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.quantity} />,
                  sortId: 'genderId',
              },
              {
                  name: (
                      <div className="w-full">
                          <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                              <HemaValue text={'Collection Date'} color="text-[#000000]" className="text-xs font-normal" />{' '}
                              <span className="short-icon">
                                  <TableSortIcon />
                              </span>
                          </div>
                          <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                              <FilterColumn
                                  columnName="Study"
                                  //  setRedux={setStudyFilter}
                                  //  reduxValues={studies?.studyFilter || []}
                                  options={Array.from(new Set(selectedStudySubjects?.allSubjects?.map((filter) => filter?.collectedUtc)))}
                                  type="boolean"
                                  boolTrueText="Active"
                                  boolFalseText="In-Active"
                              />
                          </div>
                      </div>
                  ),
                  sortable: true,
                  selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.collectedUtc} />,
                  sortId: 'study.name',
              },
              {
                  name: (
                      <div className="w-full">
                          <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                              <HemaValue text={'Test'} color="text-[#000000]" className="text-xs font-normal" />{' '}
                              <span className="short-icon">
                                  <TableSortIcon />
                              </span>
                          </div>
                          <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                              <FilterColumn
                                  columnName="Test"
                                  //  setRedux={setStudyFilter}
                                  //  reduxValues={studies?.studyFilter || []}
                                  options={Array.from(new Set(selectedStudySubjects?.allSubjects?.map((filter) => filter?.test)))}
                                  type="boolean"
                                  boolTrueText="Active"
                                  boolFalseText="In-Active"
                              />
                          </div>
                      </div>
                  ),
                  sortable: true,
                  selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.test} />,
                  sortId: 'study.name',
              },
              {
                  name: (
                      <div className="w-full">
                        <div className="py-[10px] flex items-center justify-end w-full border-b-[1px] border-solid border-[#DEE2E6] pl-[10px] pr-[30px]">
                          <HemaValue text="Actions" color="text-[#000000]" className="text-xs font-normal" />
                        </div>
                      </div>
                    ),
                    cell: (row) => (
                      <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                        <Button 
                        //text="Print"
                        icon={<PrintIcon color="#605DAF" />} 
                        cta={() =>{
                          printLabel(row);}} />
                      </div>
                    ),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
              },
          ]}
          pagination

          paginationComponent={(e) => {
              return <Pagination e={e} />;
          }}
      />

    </>
  );
};

export default Cases;
