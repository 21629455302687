import { post, get } from './http';
import { errorHandling } from './error';

const getAllNotes = async (id) => {
  try {
    return await get(`order/internal-note/record-notes/${id}`);
  } catch (err) {
    return err?.response?.data;
  }
};

const createNewNote = async (data) => {
  try {
    return await post(`order/internal-note/notes`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};


export { getAllNotes, createNewNote };
