import { post, get, put, getBlob } from './http';
import { errorHandling } from './error';

const createNewSite = async (data) => {
  try {
    return await post(`site/sites`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
  }
};

const getAllUnconfiguredSites = async (studyId) => {
  try {
    return await get(
      `logistic-builder/studies/${studyId}/not-configured-sites`
    );
  } catch (e) {
    console.log(e);
  }
};

const getSitesByStudyId = async (studyId) => {
  try {
    return await get(
      `logistic-builder/studies/${studyId}/sites`
    );
  } catch (e) {
    console.log(e);
  }
};

const getCountriesByStudyId = async (studyId) => {
  try {
    return await get(
      `logistic-builder/studies/${studyId}/countries`
    );
  } catch (e) {
    console.log(e);
  }
};

const getSitesByStudyIdAndCountryId = async (studyId, countryId) => {
  try {
    return await get(
      `logistic-builder/studies/${studyId}/countries/${countryId}/sites`
    );
  } catch (e) {
    console.log(e);
  }
};

const getAllSites = async (data) => {
  try {
    return await get(`site/sites/active-inactive`, data);
  } catch (e) {
    console.log(e);
  }
};
const editSudy = async (id, data) => {
  try {
    return await put(`site/sites/${id}`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
  }
};

const downloadBulk = async () => {
  try {
    return await getBlob(`site/sites/download`);
  } catch (err) {
    errorHandling(err?.response?.data);
  }
};

const uploadBulk = async (data) => {
  try {
    return await post(`site/sites/upload`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
  }
};

export {
  downloadBulk,
  uploadBulk,
  createNewSite,
  getAllSites,
  editSudy,
  getAllUnconfiguredSites,
  getSitesByStudyId,
  getCountriesByStudyId,
  getSitesByStudyIdAndCountryId
};
