import {  
  NameKit,
  ChangeReason,
} from '../../../HemeIconLibrary';

export const createNewCasePrefix = [
  {
    label: 'Case Prefix Name',
    icon: <NameKit />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter name',
    required: true,
    name: 'name',
  },
    {
    label: 'Document Template',
    icon: <ChangeReason />,
    type: 'select',
    initialValue: '',
    placeholder: 'Please select document template',
    required: true,
    name: 'documentTemplate',
    fluid: true,
  },
  {
    name: 'changeReason',
    label: 'Change Reason',
    placeholder: 'Type Reason',
    icon: <ChangeReason />,
    type: 'textarea',
    fluid: true,
    initialValue: '',
    required: true,
  },  
];
