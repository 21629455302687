import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import DataTable from 'react-data-table-component';

import HemaHeadingWithSubText from '../utils/HemaHeadingWithSubText';
import { FormSearch, Alert, Pagination, HemaValue, FilterColumn, NoPermissionAlert, NoDataComponent, HemaLabel, Button, SingleSelect, FormText, } from '../utils';
import { FirstName, Barcode, LabelDate, CrossIcon, Add, Edit, Confirm, TableSortIcon } from '../HemeIconLibrary';
import FormContainer from '../components/Formik/formContainer';
import { setForm, editFormReducer, showSuccessReducer, setFormLoaderReducer, setFormCloseReducer } from '../Store/reducers/uiSettings';
import { setStudyFilter } from '../Store/reducers/study';

import { sortedData } from '../helpers/sort';
import { createNewSubjectAction, getStudySubjectsAction, updateSubjectAction } from '../Actions/subject';
import { getSitesByStudyIdAction } from '../Actions/siteManagment';
import { createNewSubject } from '../components/Formik/AllForms/createNewSubject';
import createNewItemValidator from '../components/Formik/allFormValidation/createItem';
import { StudyManagementColumns } from '../components/Tables';
import {
  getAllSponserAction,
  getAllSponserStudiesAction,
} from "../Actions/sponsers";
const SubjectManagement = () => {
  const dispatch = useDispatch();
  const { uisettings, sponsers, selectedStudySubjects, common, sites } = useSelector((state) => state);

  /* form states */
  const [CTA, setCTA] = useState();
  const [formName, setformName] = useState();
  const [formIcon, setFormIcon] = useState();
  const [formValidation, setFormValidation] = useState();
  const [updatedData, setUpdatedData] = useState();
  const [searchQuery, setsearchQuery] = useState('');
  const [okBtnText, setokBtnText] = useState();
  const [okBtnIcon, setokBtnIcon] = useState();
  const [filteredData, setfilteredData] = useState([]);
  const [study, setStudy] = useState();
  const [sponsor, setSponsor] = useState();
  //callallEnventoryRequiredAPI
  useEffect(() => {
    (async () => {

      getAllSponserAction();
      
     
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if(study)
      { getStudySubjectsAction(study);
        getSitesByStudyIdAction(study);
      }
    })();
  }, [study]);

  // CTA Subject add
  const addSubjectCTA = (edit) => {
    setCTA(() => async (data) => {
      const fildata = {
        ...data,
        // logisticOrchestratorStatus: data.logisticOrchestratorStatus === undefined ? false : data.logisticOrchestratorStatus,
        // nameAlias: data.nameAlias === undefined ? '' : data.nameAlias,
        // studyCodeAlias: data.studyCodeAlias === undefined ? '' : data.studyCodeAlias,
      };
      if (edit) {
        dispatch(setFormLoaderReducer(true));
        const editSponser = await updateSubjectAction(edit, {
          ...fildata,
          studyId:study,
          changeReason: fildata.changeReason.trim(),
        });
        dispatch(setFormLoaderReducer(false));
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`${editSponser.data.subjectNumber} Subject updated.`));
      } else {
        dispatch(setFormLoaderReducer(true));
        const createdSponser = await createNewSubjectAction({...fildata, studyId:study});
        if (createdSponser?.data?.id) {
          dispatch(setFormCloseReducer());
          dispatch(showSuccessReducer(`${createdSponser.data.subjectNumber} Subject added.`));
        }
        dispatch(setFormLoaderReducer(false));
      }
    });
    setFormValidation((error, values) => (errors, values) => {
      createNewItemValidator(errors, values);
      if (values?.subjectNumber?.length > 150) {
        errors.subjectNumber = 'Max 150 characters allowed';
      }
      return errors;
    });
  };

  // CTA for inventory for selected item
  // filter and search

  useEffect(() => {

    const regex = new RegExp(searchQuery, 'i');
    (async () => {
      if (searchQuery) {
        const data = selectedStudySubjects?.allSubjects?.filter((obj) => {
          for (const key in obj) {
            if (regex.test(obj[key])) {
              return true;
            }
            if (key === 'sponsor') {
              const childObj = obj[key];
              for (const k in childObj) {
                if (regex.test(childObj[k])) {
                  return true;
                }
                // return false;
              }
            }
          }
          return false;
        });
        setfilteredData(data);
      } else {
        setfilteredData(selectedStudySubjects?.allSubjects);
      }
    })();
  }, [searchQuery, selectedStudySubjects]);

  // study filter
  useEffect(() => {
    if (false ){//&& Object.keys(selectedStudySubjects?.allSubjects?.studyFilter)?.length) {
      const filterResult = selectedStudySubjects.allSubjects?.filter((port) => {
        if (true
          // (studies?.studyFilter?.name?.length ? studies?.studyFilter?.name.includes(port.name) : true) &&
          // (studies?.studyFilter?.sponsor?.length ? studies?.studyFilter.sponsor?.includes(port.sponsor?.name) : true) &&
          // (studies?.studyFilter?.studyCode?.length ? studies?.studyFilter.studyCode?.includes(port.studyCode) : true) &&
          // (studies?.studyFilter?.nameAlias?.length ? studies?.studyFilter.nameAlias?.includes(port.nameAlias) : true) &&
          // (studies?.studyFilter?.nameAlias?.length ? studies?.studyFilter?.nameAlias.includes(port.nameAlias) : true) &&
          // (studies?.studyFilter?.studyCodeAlias?.length ? studies?.studyFilter.studyCodeAlias?.includes(port.studyCodeAlias) : true) &&
          // (studies?.studyFilter?.isActive?.length ? studies?.studyFilter.isActive?.includes(port.isActive) : true)
        ) {
          return true;
        } else {
          return false;
        }
      });
      setfilteredData(filterResult);
    } else {
      setfilteredData(selectedStudySubjects?.allSubjects);
    }
  },[selectedStudySubjects]// [JSON.stringify(studies?.studyFilter)]
);

  const setSponsorFieldValue = (x, id) => {
    setSponsor(id)
    if (Number.isInteger(id) && id != 0)
      getAllSponserStudiesAction(id);
  }

  if (!common?.permissions?.['Study Management']?.read) {
    return <NoPermissionAlert />;
  }
  return (
    <>
      <div className="flex gap-[10px] justify-between items-end">
        <HemaHeadingWithSubText heading="Subject Management" sub="Manage your subjects here." />
        <div className="flex gap-[10px] ">
          {common?.permissions?.['Study Management']?.write && study && (
            <Button
              text="Create New Subject"
              icon={<Add color="white" />}
              color="text-white"
              bg="bg-primary1"
              cta={() => {
                setformName('Create Subject');
                createNewSubject[0].options = sites?.selectedStudySites;
                createNewSubject[0].initialValue = '';
                createNewSubject[1].initialValue = '';
                createNewSubject[2].initialValue = '';
                createNewSubject[3].initialValue = 1;
                createNewSubject[3].options = [{"id":1,"name":"Male"},{"id":2,"name":"Female"}];
                // setFormIcon(<CreateSite />);
                setokBtnText('Create');
                setokBtnIcon(<Add color="white" />);
                setUpdatedData(createNewSubject?.filter((item) => item.name !== 'changeReason'));
                addSubjectCTA();
                dispatch(setForm({ state: true, type: 'create-Subject' }));
              }}
            />
          )}
        </div>
      </div>

      <Alert />
      <div className="bg-white rounded-[5px] pt-[16px] pb-[10px] mt-[27px] mb-[13px] inventory-tabs">
        <>
          <div className="bg-white rounded-[5px] pt-[4px] pb-[5px] px-4 mt-[5px] mb-[5px] ">
            <div className={`flex items-start gap-[16px] mb-3`}>
              <div className={`w-full `}>
                <HemaLabel
                  text="Sponsor"
                  className={'mb-[10px]'}
                  required={true}
                  icon={<FirstName />}
                />


                <SingleSelect
                  name={'sponsor'}
                  // onChange={handleChange}
                  setFieldValue={(e, r) => {
                    setSponsorFieldValue(e, r)
                  }
                  }
                  value={sponsor}
                  options={sponsers?.allSponsers?.filter((data) => data.isActive) || []}
                //  formikRef={ref}
                />
                {/* {!sponsor && <div className="error text-[red] text-[12px] pt-[2px]">Required</div>} */}

              </div>

              <div className={`w-full`}>
                <HemaLabel
                  text="Study #"
                  className={'mb-[10px]'}
                  required={true}
                  icon={<Barcode purple />}
                />

                <SingleSelect
                  name={'study'}
                  value={study ? study : null}
                  options={sponsers?.sponerStudy || []}
                  setFieldValue={(e,r)=>
                  {
                    setStudy(r)
                  }
                  }
                />
                {/* {!values.study && <div className="error text-[red] text-[12px] pt-[2px]">Required</div>} */}
              </div>

            </div>


           
          </div>
          <div className="mb-[16px] pl-[16px]" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <FormSearch w="w-[700px]" searchQuery={searchQuery} setsearchQuery={setsearchQuery} />
          </div>

          {true ? (
            //filteredData?.length > 0 || Object.keys(studies?.studyFilter)?.length
            true ? (
              <DataTable
                customStyles={{
                  responsiveWrapper: {
                    style: { overflow: 'visible !important' },
                  },
                }}
                noDataComponent={<NoDataComponent setActiveFilterTab={setfilteredData} activeFilterOriginalData={selectedStudySubjects?.allSubjects} resetRedux={setStudyFilter} />}
                data={filteredData}
                className="hdxa-rdt-filter border-t-[1px] border-solid border-[#DEE2E6] managment-table-st"
                columns={[
                 
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Subject Number'} color="text-[#000000]" className="text-xs font-normal" />{' '}
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="subjectNumber"
                           // setRedux={setStudyFilter}
                          //  reduxValues={studies?.studyFilter || []}
                            options={Array.from(new Set(selectedStudySubjects?.allSubjects?.map((filter) => filter?.subjectNumber)))}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.subjectNumber} />,
                    sortId: 'subjectNumber',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Site Name'} color="text-[#000000]" className="text-xs font-normal whitespace-pre" />
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="siteName"
                          //  setRedux={setStudyFilter}
                          //  reduxValues={studies?.studyFilter || []}
                            options={Array.from(new Set(selectedStudySubjects?.allSubjects?.map((filter) => filter?.site?.name)))}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.site?.name} />,
                    sortId: 'siteName',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Sex'} color="text-[#000000]" className="text-xs font-normal whitespace-pre" />
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="genderId"
                           // setRedux={setStudyFilter}
                           // reduxValues={studies?.studyFilter || []}
                            options={Array.from(new Set(selectedStudySubjects?.allSubjects?.map((filter) => filter?.GenderId)))}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.genderId===1 ? 'Male':'Female'} />,
                    sortId: 'genderId',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Date Of Birth'} color="text-[#000000]" className="text-xs font-normal" />{' '}
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="dateOfBirth"
                          //  setRedux={setStudyFilter}
                          //  reduxValues={studies?.studyFilter || []}
                            options={Array.from(new Set(selectedStudySubjects?.allSubjects?.map((filter) => filter?.dateOfBirth)))}
                            type="boolean"
                            boolTrueText="Active"
                            boolFalseText="In-Active"
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.dateOfBirth?.split('T')?.[0]} />,
                    sortId: 'dateOfBirth',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <HemaValue text="Actions" color="text-[#000000]" className="text-xs font-normal" />
                        </div>
                      </div>
                    ),
                    selector: (row, index) => (
                      <div className="flex-grow four">
                        <div className="flex justify-end gap-[5px] meta">
                          {common?.permissions?.['Study Management']?.update && (
                            <Button
                              icon={<Edit color="#775FD5" />}
                              color="text-white"
                              bg="bg-bgActionDots"
                              cta={() => {
                                createNewSubject[0].options = sites?.selectedStudySites;
                                createNewSubject[0].initialValue = row?.site?.id;
                                createNewSubject[1].initialValue = row?.subjectNumber;
                                createNewSubject[2].initialValue = row?.dateOfBirth?.split('T')?.[0];
                                createNewSubject[3].initialValue = row?.genderId;
                                createNewSubject[3].options = [{"id":1,"name":"Male"},{"id":2,"name":"Female"}];
                                setUpdatedData(createNewSubject);
                                setformName('Edit Subject');
                                // setFormIcon(<ExpiryAlertPurple />);
                                dispatch(editFormReducer(row));
                                setokBtnText('Save');
                                setokBtnIcon(<Confirm />);
                                addSubjectCTA(row?.id);
                                dispatch(setForm({ state: true, type: 'edit-subject' }));
                              }}
                            />
                          )}
                          {/* <Button icon={<SearchColor />} color="text-white" bg="bg-bgActionDots" cta={() => {}} /> */}
                        </div>
                      </div>
                    ),
                  },
                ]}
                pagination
                onSort={(row, direction, sorted) => {
                  setfilteredData(sortedData(row.sortId, direction, sorted)?.filter((data) => Object.keys(data)?.length));
                }}
                paginationComponent={(e) => {
                  return <Pagination e={e} />;
                }}
              />
            ) : (
              <BootstrapAlert variant="warning" className="mt-3 text-center">
                There are no records to display.
              </BootstrapAlert>
            )
          ) : (
            <>
              <br />
              <Skeleton count={4} />
              <br />
              <Skeleton count={4} />
              <br />
              <Skeleton count={4} />
            </>
          )}
        </>
      </div>

      {uisettings?.openform && (
        <FormContainer
          cta={CTA}
          formType={updatedData}
          formName={formName}
          icon={formIcon}
          formValidation={formValidation}
          okBtnIcon={okBtnIcon}
          okBtnText={okBtnText}
          setokBtnIcon={setokBtnIcon}
          setokBtnText={setokBtnText}
        />
      )}
    </>
  );
};

export default SubjectManagement;
