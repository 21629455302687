/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allStudies: null,
  studyFilter:[],

};

export const study = createSlice({
  name: "studies",
  initialState,
  reducers: {
    setAllStudiesReducer: (state, action) => {
      state.allStudies = action.payload;
    },

    addNewStudyReducer: (state, action) => {
      if (state.allStudies) {
        state.allStudies = [action.payload, ...state.allStudies];
      } else {
        state.allStudies = [action.payload];
      }
    },
    setStudyFilter: (state, action) => {
      state.studyFilter = action.payload;
    },
    editStudyReducer: (state, action) => {
      state.allStudies = state.allStudies.map((study) => {
        if (study.id === action.payload.id) {
          return action.payload.data;
        } else {
          return study;
        }
      });
    },
    setAllCasePrefixesReducer: (state, action) => {
      state.casePrefixes = action.payload;
    },
    setSelectedStudyCasePrefixesReducer: (state, action) => {
      state.selectedStudyCasePrefixes = action.payload;
    },
    setSelectedStudyTimePointsReducer: (state, action) => {
      state.selectedStudyTimePoints = action.payload;
    },
  },
});

export const { setAllStudiesReducer, addNewStudyReducer, editStudyReducer, setStudyFilter, setAllCasePrefixesReducer, setSelectedStudyCasePrefixesReducer, setSelectedStudyTimePointsReducer } = study.actions;

export default study.reducer;
