import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HemaHeadingWithSubText from '../../utils/HemaHeadingWithSubText';
import { Alert, NoPermissionAlert } from '../../utils';
import TwoColumnsWithCards from './twoColumnsWithCard';



const CaseResult = () => {
  const { common } = useSelector((state) => state);


  if (!common?.permissions?.['Study Management']?.read) {
    return <NoPermissionAlert />;
  }
  return (
    <>
      <HemaHeadingWithSubText heading="Cases Management" sub="Manage your cases here." />

      <TwoColumnsWithCards></TwoColumnsWithCards>

      <Alert />

    </>
  );
};

export default CaseResult;
