import { getPermissions, savePermissions } from "../Services/permission";
import { store } from "../Store";
import { caseInsensitiveSort } from '../helpers/commonHelper';


const getPermissionsReducerAction = async (userId) => {
  const result = await getPermissions(userId);
  if (result && result.status === 200) {
    return result?.data;
  }
};

const savePermissionReducerAction = async (data) => {
  const result = await savePermissions(data);
  if (result && result.status === 200) {
    return result?.data;
  }
};


export { getPermissionsReducerAction, savePermissionReducerAction };
