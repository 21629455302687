import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HemaHeadingWithSubText from '../../utils/HemaHeadingWithSubText';
import { Alert, NoPermissionAlert, Button, HemaLabel, FormText, } from '../../utils';
import { showSpinner, hideSpinner, createSpinner } from '@syncfusion/ej2-popups';
import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-react-documenteditor';
import { registerLicense } from '@syncfusion/ej2-base';
import '@syncfusion/ej2-react-documenteditor/styles/material.css';
import { useLocation } from 'react-router-dom';
import PDF from '../../components/RequestDocumentForm/pdf'
import { signOffCaseReportAction } from '../../Actions/case';
import { showSuccessReducer, showErrorReducer } from '../../Store/reducers/uiSettings';


DocumentEditorContainerComponent.Inject(Toolbar);



const CaseReport = () => {
    const dispatch = useDispatch();
    const { common } = useSelector((state) => state);
    const location = useLocation();//
    const [docFile, setDocFile] = useState(location.state?.wordFileUrl || location.state?.reportFile?.storageUrl)
    const [pdfFile, setPDFFile] = useState(location.state?.PDFFileUrl)
    const [pin, setPin] = useState('')
    const editorContainer = useRef(null);
    const pdfRef = useRef(null)

    useEffect(() => {
        if (!docFile) {
            setDocFile(docFile)
        }
        createSpinner({
            // Specify the target for the spinner to show
            target: document.getElementById('container'),
        });
        async function fetchData() {
            // You can await here
            if(!pdfFile)
            await initialize();
            // ...
        }
        fetchData();


    }, [docFile, pdfFile]);

    const initialize = () => {
        showSpinner(document.getElementById('container'));
        fetch(docFile)
            .then((res) => res.blob())
            .then((myBlob) => {
                function convertBlobToFile(blob, fileName) {
                    if (blob) {
                        blob.lastModifiedDate = new Date();
                        blob.name = fileName;
                    }
                    return blob;
                }
                const myFile = convertBlobToFile(myBlob, 'HEMA-LAB-DOC');
                loadFile(myFile);
            });

        // convert docx file to sdft format and load it in editor
        const loadFile = (file) => {
            const ajax = new XMLHttpRequest();
            ajax.open('POST', 'https://ej2services.syncfusion.com/production/web-services/api/documenteditor/Import', true);
            ajax.onreadystatechange = () => {
                if (ajax.readyState === 4) {
                    if (ajax.status === 200 || ajax.status === 304) {
                        // hideSpinner() method used hide spinner
                        hideSpinner(document.getElementById('container'));
                        // open SFDT text in document editor
                        editorContainer.current?.documentEditor.open(ajax.responseText);
                    }
                }
            };
            const formData = new FormData();
            formData.append('files', file);
            ajax.send(formData);
        };
    };

    const handleChange = (e) => {
        setPin(e.target.value);
    }
    if (!common?.permissions?.['Study Management']?.read) {
        return <NoPermissionAlert />;
    }
    return (
        <>
            <HemaHeadingWithSubText heading="Case Report" sub="Sign your report off" />
            <div className="flex gap-[10px] " style={{ margin: '10px' }} >
                {/* && subjectNumber && study && site && timePoint && receivedDateTime */}
                {common?.permissions?.['Study Management']?.write && (
                    <>
                        <div style={{ maxWidth: '20 px' }}>
                            <FormText
                                type="password"
                                placeholder="Enter your new PIN"
                                name="pin"
                                onChange={handleChange}
                                //  value={ pin}
                                disabled={false}
                            />
                        </div>
                        <Button
                            text="Sign Off"
                            color="text-white"
                            bg="bg-primary1"
                            disabled={pin ? false : true}
                            cta={async () => {
                                console.log('pin', pin)
                                if (!pin || pin?.trim() === "") {
                                    dispatch(showErrorReducer(`Pin is not Provided.`));
                                    return;
                                }

                                if (editorContainer.current) {
                                    // Save the document as a DOCX Blob
                                    editorContainer.current?.documentEditor
                                        .saveAsBlob('Docx')
                                        .then(async (documentContent) => {
                                            const fileBlob = new Blob([documentContent], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                                            const formData = new FormData()
                                            formData.append('file', fileBlob)
                                            const payloadData = {
                                                pin,
                                            }
                                            formData.append('model', JSON.stringify(payloadData))
                                            const result = await signOffCaseReportAction(location.state.caseItem.id, formData)
                                            console.log(result)
                                            setPDFFile(result.data.storageUrl);
                                            dispatch(showSuccessReducer(`Pdf file Generated Successfully.`,),);
                                        }).catch((error) => {
                                            //todo: refactor alert issue
                                            dispatch(showErrorReducer(`Something wen wrong please check your PIN.`,),)

                                        });
                                }
                            }
                            }
                        />
                    </>

                )}
            </div>

            {pdfFile?.length > 0 && <Alert />}
            {!pdfFile && <Alert type='error' />}

            <div className='' style={{ margin: '10px' }}>

                {!pdfFile &&
                    <DocumentEditorContainerComponent
                        id="container"
                        height={'740px'}
                        // created={handleCreated}
                        serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
                        enableToolbar={true}
                        ref={editorContainer}
                        // enablePrint={isPrintAble ? true : false}
                        // restrictEditing={uisettings?.formName === 'view-doc-template' ? true : false}
                        showPropertiesPane={false}
                    />}

                {pdfFile &&
                    <div
                        // ref={componentRef}
                        style={{ height: '100%' }}
                        className="z-0"
                    >
                        <PDF
                            id="testetrte"
                            // key={documentdetail?.preGeneratedFile?.id}
                            uploadFile={
                                pdfFile
                            }
                            ref={pdfRef}
                        />
                    </div>
                }

            </div>

        </>
    );
};

export default CaseReport;