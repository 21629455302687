import { post, put } from './http';
import { errorHandling } from './error';

const createNewPIN = async (data) => {
  try {
    return await put(`user/users/new-pin`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const verifyUserPIN = async (data) => {
  try {
    return await post(`user/users/verify-pin`, data);
  } catch (err) {
    return err?.response?.data;
  }
};


export { verifyUserPIN, createNewPIN };