/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCasePrefixes: null,
  casePrefixFilter:[],
  allDocumentTemplates: [],
};

export const casePrefix = createSlice({
  name: "casePrefixes",
  initialState,
  reducers: {
    setAllCasePrefixesReducer: (state, action) => {
      state.allCasePrefixes = action.payload;
    },
    setAllDocumentTemplatesReducer: (state, action) => {
      state.allDocumentTemplates = action.payload;
    },
  },
});


export const { setAllCasePrefixesReducer, setAllDocumentTemplatesReducer } = casePrefix.actions;

export default casePrefix.reducer;
