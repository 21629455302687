import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';

import HemaHeadingWithSubText from '../../utils/HemaHeadingWithSubText';
import { Alert, NoPermissionAlert, HemaLabel, Button, SingleSelect, FormText, } from '../../utils';
import { FirstName, Barcode, LabelDate, Add, Request, PrintIcon } from '../../HemeIconLibrary';
import { showSuccessReducer } from '../../Store/reducers/uiSettings';

import { printLabel } from './printLabel';

import { getStudySubjectsAction } from '../../Actions/subject';
import { getSitesByStudyIdAction } from '../../Actions/siteManagment';
import {
    getAllSponserAction,
    getAllSponserStudiesAction,
} from "../../Actions/sponsers";
import {
    getStudyCasePrefixesAction, getStudyTimePointsAction
} from '../../Actions/study';
import {
    getCaseBasicInfoByAccessionNumberAction, createNewCaseAction, createNewSampleAction
} from '../../Actions/case';
import './ModalTable.css';
import Select from 'react-select';


const NewSample = () => {
    const dispatch = useDispatch();
    const { uisettings, sponsers, selectedStudySubjects, common, sites, studies } = useSelector((state) => state);

    /* form states */
    const [sponsorList, setSponsorList] = useState([]);
    const [siteList, setSiteList] = useState([]);
    const [site, setSite] = useState();
    const [siteChanged, setSiteChanged] = useState(false);
    const [studyList, setStudyList] = useState([]);
    const [study, setStudy] = useState();
    const [studyChanged, setStudyChanged] = useState(false);
    const [sponsor, setSponsor] = useState();
    const [sponsorChanged, setSponsorChanged] = useState(false);
    const [subjectNumber, setSubjectNumber] = useState('');
    const [subjectNumberChanged, setSubjectNumberChanged] = useState(false);
    const [birthDate, setBirthDate] = useState();
    const [sex, setSex] = useState();
    const [timePoint, setTimePoint] = useState();
    const [receivedDateTime, setReceivedDateTime] = useState('');
    const [receivedDateTimeChanged, setReceivedDateTimeChanged] = useState(false);
    const [inputs, setInputs] = useState([]);
    const [show, setShow] = useState(false);
    const [columns, setColumns] = useState([]);
    const [modalShow, setModalShow] = useState(false);

    const [casePrefix, setCasePrefix] = useState();
    const [casePrefixChanged, setCasePrefixChanged] = useState(false);
    const [accessionNumber, setAccessionNumber] = useState('');
    const [caseId, setCaseId] = useState('');
    const [specimenTypeText, setSpecimenTypeText] = useState('');
    const [specimenType, setSpecimenType] = useState();
    const [specimenTypeChanged, setSpecimenTypeChanged] = useState(false);
    const [quantity, setQuantity] = useState();
    const [quantityChanged, setQuantityChanged] = useState(false);
    const [collectionDateTime, setCollectionDateTime] = useState();
    const [specimenConditionText, setSpecimenConditionText] = useState('');
    const [specimenCondition, setSpecimenCondition] = useState();
    const [test, setTest] = useState();
    const [errorMessage, SetErrorMessage] = useState("");

    const specimenTypeOptions = [{ label: 'DNA', value: 1 }, { label: 'RNA', value: 2 }, { label: 'FNA', value: 3 }, { label: 'Plasma', value: 4 },
    { label: 'Serum', value: 5 }, { label: 'Bone marrow aspirate (EDTA)', value: 6 }, { label: 'Bone marrow aspirate (NaHep)', value: 7 },
    { label: 'Peripheral blood (EDTA) ', value: 8 }, { label: 'Peripheral blood (NaHep)', value: 9 }, { label: 'Bone marrow smear', value: 10 }
    ];

    const specimenConditionOptions = [{ label: 'Ambient', value: 1 }, { label: 'Refrigerated', value: 2 },
    { label: 'Frozen', value: 3 }];

    useEffect(() => {

        const baseColumns = [
            {
                name: 'Accession Number',
                selector: (row) => row['accessionNumber'],
                sortable: true,
                cell: (row) => <div title={row['accessionNumber']}>{row['accessionNumber']}</div>,
            },
            {
                name: 'Specimen Type',
                selector: (row) => row['specimenTypeText'],
                sortable: true,
                cell: (row) => <div title={row['specimenTypeText']}>{row['specimenTypeText']}</div>,
            },
            {
                name: 'Quantity',
                selector: (row) => row['quantity'],
                sortable: true,
                cell: (row) => <div title={row['quantity']}>{row['quantity']}</div>,
            },
            {
                name: 'Collection Date Time',
                selector: (row) => row['collectionDateTime'],
                sortable: true,
                cell: (row) => <div title={row['collectionDateTime']}>{row['collectionDateTime']}</div>,
            },
            {
                name: 'Specimen Condition',
                selector: (row) => row['specimenConditionText'],
                sortable: true,
                cell: (row) => <div title={row['specimenConditionText']}>{row['specimenConditionText']}</div>,
            },
            {
                name: 'Test',
                selector: (row) => row['test'],
                sortable: true,
                cell: (row) => <div title={row['test']}>{row['test']}</div>,
            }
        ]

        const actionsColumn = {
            name: 'Actions',
            cell: (row) => (
                <Button
                    //text="Print"
                    icon={<PrintIcon color="#605DAF" />} //TODO: fix print label color
                    cta={() => {
                        printLabel(row);
                    }}
                />
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        };

        setColumns([...baseColumns, actionsColumn]);
    }, []);



    useEffect(() => {
        (() => {
            getSponsorsEffect();
        })();
    }, []);

    const getSponsorsEffect = async () => {
        let result = await getAllSponserAction();
        setSponsorList(result.data);
    }

    useEffect(() => {
        (() => {
            sponsorChangedEffect();
        })();
    }, [sponsor]);

    const sponsorChangedEffect = async () => {
        setStudy(null);
        setSite(null);
        setStudyList([]);
        setSiteList([]);
    }


    const setSponsorFieldValue = async (x, id) => {
        setSponsor(id)
        if (Number.isInteger(id) && id != 0) {
            let result = await getAllSponserStudiesAction(id);
            let temp = result.data;
            temp = temp.map(item => ({ id: item.id, label: item.name }));
            setStudyList(temp);
        }
    }

    useEffect(() => {
        (async () => {
            studyChangedEffect();
        })();
    }, [study]);

    const studyChangedEffect = async () => {
        setSite(null);
        setSiteList([]);
        setInputs([]);
        if (study) {
            // getStudySubjectsAction(study.id);
            let result = await getSitesByStudyIdAction(study.id);
            let sites = result?.data ? result?.data : [];
            sites = sites.map(item => ({ id: item.id, label: item.name, pi: item.pi }));
            setSiteList(sites);
            getStudySubjectsAction(study.id);
            getStudyCasePrefixesAction(study.id);
            getStudyTimePointsAction(study.id);
        } else {
            getSitesByStudyIdAction(-1);
            getStudyCasePrefixesAction(-1);
            getStudyTimePointsAction(-1);
        }
    }

    // const getNextAccessionNumber = async (id) => {
    //     const nextAccessionNumber = await getNextAccessionNumberAction(id);
    //     setAccessionNumber(nextAccessionNumber.data);
    //     setAccessionNumberChanged(true);
    // }

    const handleAddInput = async () => {

        console.log(casePrefix, accessionNumber, specimenType, quantity, collectionDateTime, specimenCondition, test, timePoint, receivedDateTime)
        if (casePrefix && specimenType && quantity && collectionDateTime && receivedDateTime) { //setInput({ casePrefix, accessionNumber, specimenType, quantity, collectionDateTime, specimenCondition, test, timePoint, receivedDateTime })
            const sample = caseId ? { caseId, specimenTypeId: specimenType, quantity: +quantity, specimenConditionId: specimenCondition, test, collectedUtc: collectionDateTime, receivedUtc: receivedDateTime }
                : { specimenTypeId: specimenType, quantity: +quantity, specimenConditionId: specimenCondition, test, collectedUtc: collectionDateTime, receivedUtc: receivedDateTime };
            const item = { studyId: study.id, siteId: site.id, subjectNumber, DateOfBirth: birthDate, GenderId: +sex, timePointId: timePoint, casePrefixId: casePrefix, accessionNumber, sample }
            console.log('item', item)
            const newCase = caseId ? await createNewSampleAction(item.sample) : await createNewCaseAction(item);
            if (!newCase || newCase.status !== 200) {
                SetErrorMessage("Error: Failed to add new case.");
                setTimeout(() => SetErrorMessage(""), 5000);
            } else {
                setAccessionNumber(newCase?.data?.accessionNumber)
                setCaseId(newCase?.data?.id)
                setModalShow(false)
                dispatch(
                    showSuccessReducer(
                        `Added successfully.`,
                    ),
                );

                clearModalInputs();

                console.log('newCase', newCase)
                setInputs([...inputs, {
                    casePrefix, accessionNumber: newCase?.data?.accessionNumber, specimenType, specimenTypeText
                    , quantity, collectionDateTime, specimenCondition, specimenConditionText
                    , test, timePoint, receivedDateTime
                }]); // Add a new empty input block
            }
        }
    };

    const clearModalInputs = () => {
        setSpecimenType();
        setQuantity();
        setCollectionDateTime();
        setSpecimenCondition();
        setTest();
    }


    if (!common?.permissions?.['Study Management']?.read) {
        return <NoPermissionAlert />;
    }
    console.log(inputs, subjectNumber, study, site, timePoint, receivedDateTime)
    return (
        <>

            <div className="flex gap-[5px] justify-between items-end">
                <HemaHeadingWithSubText heading="New Case" sub="Please fill in all the case information details." />

            </div>

            <Alert />
            <div className="bg-white rounded-[5px] pt-[5px] pb-[10px] mt-[5px] mb-[13px] ">
                <>
                    <div className="bg-white rounded-[5px] pt-[4px] pb-[5px] px-4 mt-[5px] mb-[5px] ">
                        {/* <div className={` flex items-start gap-[16px] mb-3 `}>
                            <div >
                              

                                <FormText
                                    type="text"
                                    name="accessionNumber"
                                    onChange={(e) => {
                                         setAccessionNumber(e.target.value)
                                    }}
                                    onBlur={(e) => {
                                        console.log("Input lost focus:", e.target.value);
                                    }}
                                    placeholder=" Accession Number "
                                    value={accessionNumber}
                                    disabled={accessionNumber}
                                />
                                </div>
                              
                                <div className="flex gap-[10px] ">
                                    <Button
                                        text="Get Case Information"
                                        //icon={<Add color="white" />}
                                        color="text-white"
                                        bg="bg-primary1"
                                        cta={async() => 
                                        {
                                            const caseDto = await getCaseBasicInfoByAccessionNumberAction(accessionNumber)
                                            setSponsor(caseDto?.data?.sponsor)
                                        }
                                        }
                                        disabled={accessionNumber}
                                    />
                                </div>
                           
                        </div> */}
                        <div className={`flex items-start gap-[16px] mb-3`}>
                            <div className={`w-full `}>
                                <HemaLabel
                                    text="Sponsor"
                                    className={'mb-[10px]'}
                                    required={true}
                                    icon={<FirstName />}
                                />


                                <SingleSelect
                                    name={'sponsor'}
                                    // onChange={handleChange}
                                    setFieldValue={(e, r) => {
                                        setSponsorFieldValue(e, r)
                                    }
                                    }
                                    value={sponsor}
                                    options={sponsers?.allSponsers?.filter((data) => data.isActive) || []}
                                    disabled={accessionNumber}
                                />
                                {sponsorChanged && !sponsor && <span style={{ color: "red" }}>Required</span>}

                            </div>

                            <div className={`w-full`}>
                                <HemaLabel
                                    text="Study #"
                                    className={'mb-[10px]'}
                                    required={true}
                                    icon={<Barcode purple />}
                                />

                                <Select
                                    name='study'
                                    options={studyList}
                                    value={study ? study : null}
                                    onChange={(e) => {
                                        setStudyChanged(true);
                                        setStudy(e)
                                    }
                                    }
                                    isClearable
                                    isSearchable
                                    className="hema-single-select"
                                    isDisabled={accessionNumber}
                                />

                                {/* <SingleSelect
                                    name={'study'}
                                    value={study ? study : null}
                                    options={sponsers?.sponerStudy?.filter((data) => data?.logisticOrchestratorQcApproved) || []}
                                    setFieldValue={(e, r) => {
                                            setStudy(r);
                                            setStudyChanged(true)
                                        }
                                    }
                                /> */}
                                {studyChanged && !study && <span style={{ color: "red" }}>Required</span>}
                            </div>

                            <div className={`w-full`}>
                                <HemaLabel
                                    text="Site #"
                                    className={'mb-[10px]'}
                                    required={true}
                                    icon={<Barcode purple />}
                                />
                                <Select
                                    name='site'
                                    options={siteList}
                                    value={site ? site : null}
                                    onChange={(e) => {
                                        setSite(e)
                                        setSiteChanged(true);
                                    }
                                    }
                                    isClearable
                                    isSearchable
                                    className="hema-single-select"
                                    isDisabled={accessionNumber}
                                />


                                {siteChanged && !site && <span style={{ color: "red" }}>Required</span>}
                            </div>
                            <div className={`w-full`}>
                                <HemaLabel
                                    text="Principal Investigator"
                                    className={'mb-[10px]'}
                                    required={true}
                                    icon={<FirstName purple />}
                                />

                                <HemaLabel
                                    text={site?.pi?.name || 'No selected site'}
                                    className={'mb-[10px]'}
                                    style={{ paddingLeft: '20px', paddingTop: '10px' }}

                                />
                                {/* {!values.study && <div className="error text-[red] text-[12px] pt-[2px]">Required</div>} */}
                            </div>
                            <div className={`w-full`}>
                                <HemaLabel
                                    text="Case Prefixes #"
                                    className={'mb-[10px]'}
                                    required={true}
                                    icon={<Barcode purple />}
                                />

                                <SingleSelect
                                    name={'casePrefix'}
                                    value={casePrefix ? casePrefix : null}
                                    options={studies?.selectedStudyCasePrefixes || []}
                                    setFieldValue={(e, r) => {
                                        if (r) {
                                            const t = studies?.selectedStudyCasePrefixes?.find(x => x.id == r).name;
                                            setCasePrefix(r);
                                            setCasePrefixChanged(true);
                                            //getNextAccessionNumber(r)
                                        }

                                    }
                                    }
                                    disabled={accessionNumber}
                                />
                                {casePrefixChanged && !casePrefix && <span style={{ color: "red" }}>Required</span>}

                            </div>
                        </div>

                        <div className={` flex items-start gap-[16px] mb-3 `}>
                            <div className={`w-full `}>
                                <HemaLabel
                                    text="Subject Number"
                                    className={'mb-[10px]'}
                                    required={true}
                                    icon={<FirstName />}
                                />

                                <FormText
                                    type="text"
                                    name="subjectNumber"
                                    onChange={(e) => {
                                        console.log(e)
                                        setSubjectNumber(e.target.value)
                                        setSubjectNumberChanged(true)
                                    }}
                                    onBlur={(e) => {
                                        console.log("Input lost focus:", e.target.value);
                                    }}
                                    value={subjectNumber}
                                    disabled={accessionNumber}
                                />
                                {subjectNumberChanged && !subjectNumber && <span style={{ color: "red" }}>Required</span>}
                            </div>
                            <div className={`w-full  `}>
                                <HemaLabel
                                    text="Sex"
                                    className={'mb-[10px]'}
                                    icon={<Request purpleColor />}

                                />
                                <SingleSelect
                                    name={'Sex'}
                                    setFieldValue={(fieldName, selectedValue) => {
                                        // console.log('sex',e, r)
                                        setSex(selectedValue)
                                    }
                                    }
                                    value={sex}
                                    options={[
                                        { id: 1, name: 'Male' },
                                        { id: 2, name: 'Female' }
                                    ]}
                                    disabled={accessionNumber}
                                />
                            </div>
                            <div className={`w-full `}>
                                <HemaLabel
                                    text="Date of Birth"
                                    className={'mb-[10px]'}
                                    icon={<LabelDate purpleColor />}

                                />

                                <FormText
                                    type="date"
                                    name="birthdate"
                                    onChange={(e) => {
                                        console.log(e)
                                        setBirthDate(e.target.value)
                                    }}
                                    setFieldValue={(e, r) => {
                                        console.log(e, r)
                                        setBirthDate(r)
                                    }}
                                    value={birthDate}
                                    placeholder={'Select a date'}
                                    max={new Date()?.toISOString().split('T')[0]}
                                    disabled={accessionNumber}
                                />

                            </div>
                            <div className={`w-full`}>
                                <HemaLabel
                                    text="Time Points #"
                                    className={'mb-[10px]'}
                                    icon={<Barcode purple />}
                                />

                                <SingleSelect
                                    name={'timePoint'}
                                    value={'TP1'}
                                    options={studies?.selectedStudyTimePoints || []}
                                    setFieldValue={(e, r) => {
                                        if (r)
                                            setTimePoint(r)
                                    }
                                    }
                                    disabled={accessionNumber}
                                />
                                {/* {!values.study && <div className="error text-[red] text-[12px] pt-[2px]">Required</div>} */}
                            </div>
                            <div className={`w-full `}>
                                <HemaLabel
                                    text="Received Date/Time"
                                    className={'mb-[10px]'}
                                    required={true}
                                    icon={<LabelDate purpleColor />}

                                />

                                <FormText
                                    type="datetime-local"
                                    name="receivedDateTime"
                                    onChange={(e) => {
                                        //  console.log(e)
                                        setReceivedDateTime(e.target.value);
                                        setReceivedDateTimeChanged(true);
                                        //  validateSubjectNumber()
                                    }}

                                    value={receivedDateTime}
                                    placeholder={'Select a date'}
                                    max={new Date()?.toISOString().split('T')[0]}
                                    disabled={accessionNumber}
                                />
                                {receivedDateTimeChanged && !receivedDateTime && <span style={{ color: "red" }}>Required</span>}

                            </div>
                        </div>
                        <div className={` flex items-start gap-[16px] mb-3 `}>

                        </div>
                    </div>
                </>
            </div>

            <div className="flex gap-[10px] " >
                {/* && subjectNumber && study && site && timePoint && receivedDateTime */}
                {common?.permissions?.['Study Management']?.write && subjectNumber && sponsor && study && site && receivedDateTime && (

                    <div style={{ marginLeft: 'auto' }}>
                        <Button
                            text="Create New Sample"
                            icon={<Add color="white" />}
                            color="text-white"
                            bg="bg-primary1"
                            cta={() => setModalShow(true)}
                        />
                    </div>

                )}
            </div>


            {inputs?.length >= 0 &&

                <div>
                    <HemaHeadingWithSubText heading="Samples" sub="These samples will be created as case - one sample" />

                    <div style={{ marginTop: '10px' }} className="dt-body">
                        <DataTable
                            columns={columns}
                            data={inputs}
                            pagination
                            highlightOnHover
                            striped
                        // expandableRows
                        //expandableRowsComponent={ExpandedComponent}
                        />
                    </div>
                </div>

            }

            {modalShow
                && (
                    <div className="modalOverlay">
                        <div className="modalContent">
                            <HemaHeadingWithSubText heading="New Sample" />
                            <div className="bg-white rounded-[5px] pt-[16px] pb-[10px] mt-[27px] mb-[13px] ">

                                <>
                                    <div className="bg-white rounded-[5px] pt-[4px] pb-[5px] px-4 mt-[5px] mb-[5px] ">
                                        <div className={` flex items-start gap-[16px] mb-3 `}>



                                            {/* <div className={`w-full `}>
                                                <HemaLabel
                                                    text="Accession Number"
                                                    className={'mb-[10px]'}
                                                    required={true}
                                                    icon={<Barcode />}
                                                />

                                                <FormText
                                                    type="text"
                                                    name="accessionNumber"
                                                    value={accessionNumber}
                                                    disabled={true}
                                                />
                                                {accessionNumberChanged && !accessionNumber && <span style={{ color: "red" }}>Required</span>}
                                            </div> */}

                                            <div className={`w-full  `}>
                                                <HemaLabel
                                                    text="Specimen Type"
                                                    className={'mb-[10px]'}
                                                    required={true}
                                                    icon={<Request purpleColor />}

                                                />


                                                <SingleSelect
                                                    name={'specimenType'}
                                                    setFieldValue={(e, r) => {
                                                        let option = specimenTypeOptions.find(x => x.value == r);
                                                        setSpecimenTypeText(option.label);
                                                        setSpecimenType(r)
                                                        setSpecimenTypeChanged(true);
                                                    }
                                                    }
                                                    value={specimenType}
                                                    options={specimenTypeOptions}
                                                />
                                                {specimenTypeChanged && !specimenType && <span style={{ color: "red" }}>Required</span>}
                                            </div>

                                            <div className={`w-full `}>
                                                <HemaLabel
                                                    text="Quantity"
                                                    className={'mb-[10px]'}
                                                    required={true}
                                                    icon={<Barcode />}
                                                />

                                                <FormText
                                                    type="number"
                                                    name="quantity"
                                                    onChange={(e) => {
                                                        console.log('number quantity', e)
                                                        setQuantity(e.target.value);
                                                        setQuantityChanged(true);
                                                    }}
                                                    value={quantity}
                                                    disabled={false}
                                                />
                                                {quantityChanged && !quantity && <span style={{ color: "red" }}>Required</span>}
                                            </div>

                                        </div>
                                        <div className={` flex items-start gap-[16px] mb-3 `}>
                                            <div className={`w-full `}>
                                                <HemaLabel
                                                    text="Collection Date/Time"
                                                    className={'mb-[10px]'}
                                                    required={true}
                                                    icon={<LabelDate purpleColor />}

                                                />

                                                <FormText
                                                    type="datetime-local"
                                                    name="collectionDateTime"
                                                    onChange={(e) => {
                                                        setCollectionDateTime(e.target.value)

                                                    }}
                                                    value={collectionDateTime}
                                                    placeholder={'Select a date'}
                                                    max={new Date()?.toISOString().split('T')[0]}
                                                />

                                            </div>


                                            <div className={`w-full  `}>
                                                <HemaLabel
                                                    text="Specimen Condition"
                                                    className={'mb-[10px]'}
                                                    icon={<Request purpleColor />}

                                                />
                                                <SingleSelect
                                                    name={'specimenCondition'}
                                                    setFieldValue={(e, r) => {
                                                        let option = specimenConditionOptions.find(x => x.value == r);
                                                        setSpecimenConditionText(option.label);
                                                        setSpecimenCondition(r)
                                                    }
                                                    }
                                                    value={specimenCondition}
                                                    options={specimenConditionOptions}
                                                />
                                            </div>
                                            <div className={`w-full  `}>
                                                <HemaLabel
                                                    text="Test"
                                                    className={'mb-[10px]'}
                                                    icon={<Request purpleColor />}
                                                />
                                                <FormText
                                                    type="text"
                                                    name="test"
                                                    onChange={(e) => {
                                                        setTest(e.target.value)
                                                    }}
                                                    value={test}
                                                    disabled={false}
                                                />
                                            </div>


                                        </div>
                                    </div>

                                </>
                            </div>
                            <div className="flex gap-[16px]" style={{ justifySelf: "end" }}>
                                {errorMessage && (
                                    <div style={{ color: 'red', padding: '10px', fontSize: '14px' }}>
                                        {errorMessage}
                                    </div>
                                )}
                                <Button
                                    text="Close"

                                    color="text-white"
                                    bg="bg-primary1"
                                    cta={() => { setModalShow(false); clearModalInputs(); }}
                                />
                                <Button
                                    text="Add"

                                    color="text-white"
                                    bg="bg-primary1"
                                    cta={handleAddInput}
                                />
                            </div>
                        </div>
                    </div>
                )}



        </>
    );
};

export default NewSample;
