import { createNewSubject, getStudySubjects, editSubject, } from "../Services/subject";
import { store } from "../Store";
import {
  setAllSubjectsReducer,
  addNewSubjectReducer,
  editSubjectReducer,
} from "../Store/reducers/subject";
import { caseInsensitiveSort } from '../helpers/commonHelper';

const getStudySubjectsAction = async (studyId,data) => {
  const result = await getStudySubjects(studyId, data);
  store.dispatch(setAllSubjectsReducer(result?.data?.sort(caseInsensitiveSort)));
};
const createNewSubjectAction = async (data) => {
  const result = await createNewSubject(data);
  if (result?.data) {
    store.dispatch(addNewSubjectReducer(result?.data));
  }
  return result;
};
const updateSubjectAction = async (sid, data) => {
  const result = await editSubject(sid, data);
  store.dispatch(editSubjectReducer({ id: sid, data: result.data }));
  return result;
};

export { createNewSubjectAction, getStudySubjectsAction, updateSubjectAction,};
