import {
  createNewSite,
  getAllSites,
  downloadBulk,
  uploadBulk,
  editSudy,
  getAllUnconfiguredSites,
  getSitesByStudyId,
  getCountriesByStudyId,
  getSitesByStudyIdAndCountryId,
} from '../Services/siteManagment';
import { store } from '../Store';
import {
  setallSitesReducer,
  addNewSiteReducer,
  editSiteReducer,
  setSelectedStudySitesReducer,
  setSelectedStudyCountriesReducer,
  setSelectedStudyAndCountrySitesReducer
} from '../Store/reducers/siteManagment';
import { caseInsensitiveSort } from '../helpers/commonHelper';

const getAllSiteAction = async (data) => {
  const result = await getAllSites(data);
  if (result.status === 200) {
    store.dispatch(setallSitesReducer(result?.data?.sort(caseInsensitiveSort)));
  }
};
const getAllUnconfiguredSiteAction = async (id) => {
  const result = await getAllUnconfiguredSites(id);
  store.dispatch(setallSitesReducer(result?.data?.sort(caseInsensitiveSort)));
};
const getSitesByStudyIdAction = async (id) => {
  const result = await getSitesByStudyId(id);
  store.dispatch(setSelectedStudySitesReducer(result?.data?.sort(caseInsensitiveSort)));
  return result;
};
const getCountriesByStudyIdAction = async (id) => {
  const result = await getCountriesByStudyId(id);
  store.dispatch(setSelectedStudyCountriesReducer(result?.data?.sort(caseInsensitiveSort)));
  return result;
};
const getSitesByStudyIdAndCountryIdAction = async (studyId, countryId) => {
  const result = await getSitesByStudyIdAndCountryId(studyId, countryId);
  store.dispatch(setSelectedStudyAndCountrySitesReducer(result?.data?.sort(caseInsensitiveSort)));
  return result;
};
const createNewSiteAction = async (data) => {
  const result = await createNewSite(data);
  if (result?.data) {
    store.dispatch(addNewSiteReducer(result?.data));
  }
  return result;
};
const updateSiteAction = async (sid, data) => {
  const result = await editSudy(sid, data);
  store.dispatch(editSiteReducer({ id: sid, data: result.data }));
  return result;
};

const sitesUploadAction = async (data) => {
  const result = await uploadBulk(data);
  return result;
};

const sitesDownloadAction = async () => {
  const result = await downloadBulk();
  return result;
};

export {
  sitesUploadAction,
  sitesDownloadAction,
  createNewSiteAction,
  getAllSiteAction,
  updateSiteAction,
  getAllUnconfiguredSiteAction,
  getSitesByStudyIdAction,
  getCountriesByStudyIdAction,
  getSitesByStudyIdAndCountryIdAction,
};
