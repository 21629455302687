import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, Spinner } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";

import FormContainer from "../../components/Formik/formContainer";
import { useReactToPrint } from "react-to-print";
import { BuildValidator } from "../../components/Formik/allFormValidation";
import RequestInformaction from "./requestInformaction";
import { Button, HemaValue, Alert } from "../../utils";
import HemaHeadingWithSubText from "../../utils/HemaHeadingWithSubText";
import { ScreeningKitBuild } from "../../components/Formik/AllForms/ScreeningKitBuilder";
import { quantityChcek } from "../../components/Formik/allFormValidation";
import {
  getAllSponserAction,
  getAllSponserStudiesAction,
  getAllSponserSitesAction,
} from "../../Actions/sponsers";
import { getTestingLabsAction } from "../../Actions/assembly";
import { addtoShipment } from "../../components/Formik/AllForms/addtoshipment";
import {
  getdefaultOrderValuesAction,
  orderAvailableItemsAction,
  getAllAddressAction,
  getAllbuildPreparationAction,
  getAllShippingStatsAction,
  createNewShipmentAction,
  getShipmentDetailAction,
  shiptheShipmentAction,
  updateShipmentAction,
  getQualityControlItemAction,
  getOrderDetailAction,
  getAllTypeAction,
  getAllDocumentsForOrderAction,
  AssignPreparationKitAction,
  getReadyInvenotryItemAction,
} from "../../Actions/order";

import { getAllTasksAction, getTaskStatusAction } from "../../Actions/tasks";
import { getAllNotesAction } from "../../Actions/internalNotes";

import { getAllCuriorsListAction } from "../../Actions/logistic";
import { getItemCategoryByIdAction } from "../../Actions/itemCategory";
import { getSystemUserAction } from "../../Actions/settings";
import {
  getCountriesLogisticBuilder,
  getAssemblyInboundConditionAction,
} from "../../Actions/logistic";
import { getType } from "../../helpers/getType";
import { setActiveSite } from "../../Store/reducers/orders";
import { addNewInventoryCheck } from "../../components/Formik/AllForms/addInventoryCheck";
import { addNewInventory } from "../../components/Formik/AllForms/addInventory";
import {
  Assemblies,
  TabTask,
  Confirm,
  TabShipping,
  CreateItemPurple,
  TabNotebook,
  TabInfo,
  PreviousIcon,
  Print,
  NextIcon,
  LabelDate,
  LockOpen,
  CloseLock,
  Add,
  UserBy,
  Owner,
  LastActivity,
  AddInventoryPurple,
  Assign,
  BlackBarcode,
} from "../../HemeIconLibrary";
import {
  getAllItemAction,
  addItemInventoryAction,
  getItemByIdAction,
} from "../../Actions/inventory";
import { getKitBuilderAction } from "../../Actions/kitBuilder";
import ShippingRequest from "./shipping/index";
import {
  setForm,
  setFormLoaderReducer,
  setFormCloseReducer,
  showSuccessReducer,
  showErrorReducer,
  setBuildTemplate,
  editFormReducer,
} from "../../Store/reducers/uiSettings";
import RequestPreparation from "./preparation";
import RequestDocumentForm from "../../components/RequestDocumentForm";
import TaskAssignment from "./taskAssignment";
import NoteBook from "./notebook";
import { setActiveOrder } from "../../Store/reducers/orders";

const CreateKit = ({ setShowDashboard }) => {
  const [activeTab, setactiveTab] = useState("information");
  const [shippingTabs, setShippingTabs] = useState();
  const [subTab, setsubTab] = useState("building");
  const [CTA, setCTA] = useState();
  const [formName, setformName] = useState();
  const [formIcon, setFormIcon] = useState();
  const [formValidation, setFormValidation] = useState();
  const [updatedData, setUpdatedData] = useState();
  const [allItemsInTemplate, setAllItemsInTemplate] = useState();
  const [dropdownItemList, setDropDownItemList] = useState();
  const [okBtnText, setokBtnText] = useState();
  const [openRequestDoc, setopenRequestDoc] = useState(false);
  const [okBtnIcon, setokBtnIcon] = useState();
  const [searchQuery, setsearchQuery] = useState("");
  const [cancelBtnText, setCancelBtnText] = useState();
  const [cancelBtnIcon, setSancelBtnIcon] = useState();
  const [buildPreparation, setbuildPreparation] = useState([]);
  const [stagingPreparation, setstagingPreparation] = useState([]);
  const [shippingPreparation, setShippingPreparation] = useState([]);
  const [requestLoader, setRequestLoader] = useState(false);
  const [payloadQc, setPayloadQc] = useState();
  const [inventoryItems, setinventoryItems] = useState([]);
  const [items, setItems] = useState([]);
  const [loaderAssignment, setLoaderAssignment] = useState();
  const formikControl = useRef();
  const formikControlAssigment = useRef();
  const dispatch = useDispatch();
  const { uisettings, common, orders, settings, builder } = useSelector(
    (state) => state,
  );
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const navigate = useNavigate();
  useEffect(() => {
    getAllSponserAction();
    getAllAddressAction();
    getTestingLabsAction();
    getSystemUserAction();
    dispatch(setBuildTemplate({}));
    getCountriesLogisticBuilder();
    getAllCuriorsListAction();
    getAssemblyInboundConditionAction();
    getAllTypeAction();
    getTaskStatusAction();
    getKitBuilderAction();
  }, []);
  useEffect(() => {
    (async () => {
      const res = await getAllItemAction();
      if (res?.status === 200) {
        setinventoryItems(res?.data);
      }
    })();
  }, []);

  useEffect(() => {
    if (orders.activeOrder?.id) {
      //getAllShipmentForOrderIdAction(orders.activeOrder?.id);
      getAllbuildPreparationAction("building", orders.activeOrder?.id);
      getAllbuildPreparationAction("staging", orders.activeOrder?.id);
      getAllShippingStatsAction(orders.activeOrder?.id);
      getAllTasksAction(orders.activeOrder?.id);
      getAllNotesAction(orders.activeOrder?.id);

      getAllDocumentsForOrderAction(orders.activeOrder?.id);
    }
  }, [orders.activeOrder?.id]);
  useEffect(() => {
    (async () => {
      setbuildPreparation(orders?.allBuildPreparation);
      setstagingPreparation(orders?.allStagingPreparation);
      setShippingPreparation(orders?.allShipping);
      // }
    })();
  }, [searchQuery, orders]);

  useEffect(() => {
    if (common.activeDropdownValue?.name === "sponsor") {
      getAllSponserStudiesAction(common.activeDropdownValue?.id);
    } else if (common.activeDropdownValue?.name === "study") {
      getAllSponserSitesAction(common.activeDropdownValue?.id);
    } else if (common.activeDropdownValue?.name === "siteCode") {
      getdefaultOrderValuesAction(common.activeDropdownValue?.id, orders.type);
      orderAvailableItemsAction(common.activeDropdownValue?.id, orders.type);
      dispatch(setActiveSite(common.activeDropdownValue?.id));
    }
  }, [common.activeDropdownValue]);

  useEffect(() => {
    if (orders?.activeOrder?.site) {
      orderAvailableItemsAction(orders?.activeOrder.site.id, orders.type);
      dispatch(setActiveSite(orders?.activeOrder.site.id));
    }
  }, [orders?.activeOrder?.site]);

  const addToShipmentAction = () => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true));
      console.log("payload", data);
      var resp;
      if (data?.shipmentId) {
        resp = await updateShipmentAction(
          orders.activeOrder?.id,
          data?.shipmentId,
          stagingPreparation
            ?.filter((stage) => stage?.checked)
            ?.map((data) => data?.id),
        );
      } else {
        resp = await createNewShipmentAction(orders.activeOrder?.id, {
          name: data?.name,
          ids: stagingPreparation
            ?.filter((stage) => stage?.checked)
            ?.map((data) => data?.id),
        });
      }

      if (resp?.status === 200) {
        dispatch(setFormLoaderReducer(false));
        getAllbuildPreparationAction("staging", orders.activeOrder?.id);
        getAllbuildPreparationAction("building", orders.activeOrder?.id);
        getAllShippingStatsAction(orders.activeOrder?.id);
        dispatch(setFormCloseReducer());
        dispatch(
          showSuccessReducer(`Items successfully added to ${data?.name}`),
        );
      }
    });
    setFormValidation(() => (error, values, store) => {
      if (values.name && values.shipmentId) {
        error.shipmentId = "Select only name or existing shipment";
        return error;
      }
    });
  };

  useEffect(() => {
    if (orders?.qualityControlItems?.items) {
      setPayloadQc(
        orders?.qualityControlItems?.items
          ?.filter((data) => data.qualityCheck)
          .map((data) => {
            return { id: data?.id, check: true };
          }),
      );
    }
  }, [orders?.qualityControlItems]);

  useEffect(() => {
    if (uisettings?.editForm?.remainingItem) {
      ScreeningKitBuild[5].disabled = false;
    } else {
      ScreeningKitBuild[5].disabled = true;
    }
  }, [uisettings?.editForm]);

  // CTA for inventory for selected item
  useEffect(() => {
    if (common.switch.threshold) {
      addNewInventory[3].required = true;
      addNewInventory[3].disabled = false;
      addNewInventory[8].required = true;
      addNewInventory[8].disabled = false;
      addNewInventory[9].disabled = false;
    } else {
      addNewInventory[3].required = false;
      addNewInventory[8].required = false;
      addNewInventory[8].disabled = true;
      addNewInventory[9].disabled = true;
    }
  }, [common.switch]);
  useEffect(() => {
    if (common?.activeDropdownValue?.name === "shipmentId") {
      addtoShipment[1].disabled = true;
    } else {
      addtoShipment[1].disabled = false;
    }
  }, [common.activeDropdownValue]);

  const addInventoryForItemCTA = async () => {
    setCTA(() => async (id, data, values) => {
      dispatch(setFormLoaderReducer(true));
      delete data.StorageLocation;
      const createNewIem = await addItemInventoryAction(id, {
        fields: data,
        userIds: !values?.CloseToExpiryAlert
          ? undefined
          : values?.userIds?.map((d) => d.id) || [],
      });
      if (createNewIem?.data?.id) {
        getAllItemAction();
        // dispatch(setFormCloseReducer());
        dispatch(
          showSuccessReducer(
            `${createNewIem.data.quantity} ${createNewIem.data.item?.name} added.`,
          ),
        );
      }
      dispatch(setFormLoaderReducer(false));
      buildAssignkitItems();
    });
  };

  const addInventoryModal = async () => {
    const result = await getAllItemAction();
    const availToBuild = uisettings?.editForm?.availableToBuild;
    if (result.status === 200) {
      const row = result?.data?.filter(
        (f) => f.id === uisettings?.editForm?.row?.item?.id,
      )?.[0];
      console.log("row", row);
      if (row.itemCategory?.name === "H-Kit") {
        const row1 = builder?.allTemplates?.filter(
          (kit) => kit.id === row?.kitId,
        )?.[0];
        if (row1) {
          dispatch(editFormReducer(row1));
        }

        setformName(`Build Kit`);

        setFormIcon(<AddInventoryPurple />);

        dispatch(
          setForm({
            state: true,
            type: "edit-kit-template",
          }),
        );
        setFormValidation(() => (error, values, store) => {
          quantityChcek(error, values, store);
        });
        setokBtnText("Build");
        setCTA(() => async (payload) => {
          dispatch(setFormLoaderReducer(false));
          //todo: I commented out these two lines because they prevent the print label form from appearing. We can address this by displaying the two interfaces sequentially at a more appropriate time later on.
          //buildAssignkitItems();
          //dispatch(editFormReducer({ availableToBuild: availToBuild }));
        });
      } else {
        const result = await getItemCategoryByIdAction(row.itemCategory.id);

        var customEnventory = [];
        result.data?.fields?.forEach((field, index) => {
          var obj = {};
          if (!field.isStandard) {
            obj.type = getType(field.format?.name);
            obj.name = field.id || undefined;
            obj.label = field.name || undefined;
            obj.initialValue = field.value || undefined;
            obj.required = field.isRequired || false;
            obj.placeholder = field.placeholder || field.name;
            obj.options = field.options || [];
            customEnventory.push(obj);
          }
        });
        setokBtnText("Save");
        setformName("Add Inventory");
        setFormIcon(<Assemblies purple />);
        addNewInventory[0].initialValue = row?.id;
        addNewInventory[0].placeholder = row.name;
        addNewInventory[0].disabled = true;
        addNewInventory[7].initialValue = false;
        addNewInventory[8].disabled = true;
        addNewInventory[8].required = false;
        addNewInventory[9].required = false;
        addNewInventory[9].options = settings?.systemUsers?.map((data) => {
          return {
            id: data.id,
            name: data.firstName + " " + data.lastName,
          };
        });
        addNewInventory[4].required =
          result.data?.fields?.filter(
            (data) => data.mergeFieldKey === "SerialNumber",
          )?.[0]?.isRequired || false;

        addNewInventory[5].required =
          result.data?.fields?.filter(
            (data) => data.mergeFieldKey === "Barcode",
          )?.[0]?.isRequired || false;

        addNewInventory[7].required =
          result.data?.fields?.filter(
            (data) => data.mergeFieldKey === "CloseToExpiryAlert",
          )?.[0]?.isRequired || false;
        addNewInventory[7].initialValue =
          result.data?.fields?.filter(
            (data) => data.mergeFieldKey === "CloseToExpiryAlert",
          )?.[0]?.isRequired || false;
        addNewInventory[7].disabled =
          !result.data?.fields?.filter(
            (data) => data.mergeFieldKey === "CloseToExpiryAlert",
          )?.[0]?.isRequired || false;

        addNewInventory[8].required =
          result.data?.fields?.filter(
            (data) => data.mergeFieldKey === "CloseToExpiryAlert",
          )?.[0]?.isRequired || false;
        addNewInventory[8].disabled =
          !result.data?.fields?.filter(
            (data) => data.mergeFieldKey === "CloseToExpiryAlert",
          )?.[0]?.isRequired || false;

        addNewInventory[3].required =
          result.data?.fields?.filter(
            (data) => data.mergeFieldKey === "ExpirationDate",
          )?.[0]?.isRequired || false;

        setUpdatedData([...addNewInventory, ...customEnventory]);
        addInventoryForItemCTA();
        dispatch(setForm({ state: true, type: "addInventory" }));
      }
    }
  };

  const buildAssignkitItems = async (row) => {
    const resp = await getReadyInvenotryItemAction(
      uisettings?.editForm?.row?.item?.id || row?.item?.id,
      1,
    );
    if (resp.status === 200) {
      const selectedInventory = resp?.data;
      const itemName = row?.item?.name;
      setformName("Inventory Management");
      setFormIcon(<CreateItemPurple />);
      setokBtnText("Assign");
      setokBtnIcon(<Assign color="#fff" />);
      setCancelBtnText("Cancel");

      ScreeningKitBuild[1].label = "Item Name:";
      ScreeningKitBuild[1].initialValue = itemName;
      ScreeningKitBuild[2].label = "Number of items left to assign:";
      ScreeningKitBuild[2].initialValue =
        uisettings?.editForm?.row?.remainingQuantity ||
        row?.remainingQuantity ||
        "0";
      ScreeningKitBuild[4].initialValue = selectedInventory;
      setFormValidation(() => (error, values, store) => {
        BuildValidator(error, values, store);
      });
      setUpdatedData(ScreeningKitBuild);
      dispatch(
        setForm({
          state: true,
          type: "build-screening-kit",
        }),
      );
      setCTA(() => async (payload) => {
        dispatch(setFormLoaderReducer(true));

        const filterpayload = {
          quantity: payload.build_qty,
          orderItemId: uisettings?.editForm?.row?.id || row?.id,
          itemRecordId:
            payload?.recordItemId?.filter?.((item) => item.checked)?.[0]?.id ||
            null,
        };
        if (filterpayload?.itemRecordId) {
          const resp = await AssignPreparationKitAction(
            orders?.activeOrder?.id,
            filterpayload,
          );
          if (resp?.status === 200) {
            console.log("build", resp);
            dispatch(
              showSuccessReducer(
                `${payload.build_qty} ${row?.item?.name} successfully built.`,
              ),
            );
            getAllbuildPreparationAction("building", orders.activeOrder?.id);
            getAllbuildPreparationAction("staging", orders.activeOrder?.id);
            dispatch(setFormLoaderReducer(false));
            dispatch(setFormCloseReducer());
            setokBtnIcon("");
          }
        } else {
          dispatch(
            showErrorReducer(
              `There is no inventory selected, please select inventory from the ready tab.`,
            ),
          );
          dispatch(setFormLoaderReducer(false));
        }
      });
    }
  };

  if (!uisettings?.buildTemplate?.open) {
    return (
      <>
        <div className="mb-[27px]">
          <div className="flex items-center gap-[10px] mb-1">
            <HemaHeadingWithSubText
              HeadingFontSize="30px"
              HeadingLineHeight="38px"
              heading={
                orders?.activeOrder?.id ? "Kit Request" : "New Kit Request"
              }
            />
            <div
              className={`w-fit rounded-full px-3 py-1  flex items-center justify-center gap-1 ${
                (orders?.activeOrder?.header?.status?.name === "New" &&
                  "bg-[#D6D7FF]") ||
                (orders?.activeOrder?.header?.status?.name === "In Process" &&
                  "bg-[#fbe8db]") ||
                (orders?.activeOrder?.header?.status?.name === "Shipped" &&
                  "bg-[#fcdaee]") ||
                (orders?.activeOrder?.header?.status?.name === "Delivered" &&
                  "bg-[#e1ffee]") ||
                "bg-[#D6D7FF]"
              } `}
            >
              <div
                className={`w-2 h-2 rounded-full flex-shrink-0 ${
                  (orders?.activeOrder?.header?.status?.name === "New" &&
                    "bg-[#4868DA]") ||
                  (orders?.activeOrder?.header?.status?.name === "In Process" &&
                    "bg-[#F97316]") ||
                  (orders?.activeOrder?.header?.status?.name === "Shipped" &&
                    "bg-[#C92286]") ||
                  (orders?.activeOrder?.header?.status?.name === "Delivered" &&
                    "bg-[#065528]") ||
                  "bg-[#4868DA]"
                } `}
              />
              <HemaValue
                color={
                  (orders?.activeOrder?.header?.status?.name === "New" &&
                    "text-[#4868DA]") ||
                  (orders?.activeOrder?.header?.status?.name === "In Process" &&
                    "text-[#F97316]") ||
                  (orders?.activeOrder?.header?.status?.name === "Shipped" &&
                    "text-[#C92286]") ||
                  (orders?.activeOrder?.header?.status?.name === "Delivered" &&
                    "text-[#065528]") ||
                  "text-[#4868DA]"
                }
                className=""
                text={orders?.activeOrder?.header?.status?.name || "New"}
              />
            </div>
          </div>
          <div className="flex flex-wrap items-center gap-3">
            <div className="flex items-center gap-1">
              {orders?.activeOrder?.header?.type &&
                (orders?.activeOrder?.header?.type?.id !== 1 ? (
                  <LockOpen />
                ) : (
                  <CloseLock />
                ))}
              {orders?.activeOrder?.header?.type && (
                <HemaValue
                  className="font-normal text-[16px] leading-[24px]"
                  text={`Type: ${orders?.activeOrder?.header?.type?.name}`}
                />
              )}
            </div>
            {orders?.activeOrder?.header?.lastModifiedUtc && (
              <>
                <div className="w-[6px] h-[6px] rounded-full bg-[#2C2C2C]" />
                <div className="flex items-center gap-1">
                  <LastActivity />
                  <HemaValue
                    className="font-normal text-[16px] leading-[24px]"
                    text={`Last Activity: ${new Date(
                      orders?.activeOrder?.header?.lastModifiedUtc,
                    )?.toLocaleString()}`}
                  />
                </div>
              </>
            )}

            {orders?.activeOrder?.header?.lastModifiedBy?.name && (
              <>
                {/* <div className="w-[6px] h-[6px] rounded-full bg-[#2C2C2C]" /> */}
                <div className="flex items-center gap-1">
                  <UserBy />

                  <HemaValue
                    className="font-normal text-[16px] leading-[24px]"
                    text={`By: ${orders?.activeOrder?.header?.lastModifiedBy?.name}`}
                  />
                </div>
              </>
            )}
            {orders?.activeOrder?.header?.owner?.name && (
              <>
                <div className="w-[6px] h-[6px] rounded-full bg-[#2C2C2C]" />
                <div className="flex items-center gap-1">
                  <Owner />

                  <HemaValue
                    className="font-normal text-[16px] leading-[24px]"
                    text={`Owner: ${orders?.activeOrder?.header?.owner?.name}`}
                  />
                </div>
              </>
            )}
            {orders?.activeOrder?.site?.pi?.name && (
              <>
                <div className="w-[6px] h-[6px] rounded-full bg-[#2C2C2C]" />
                <div className="flex items-center gap-1">
                  <Owner />

                  <HemaValue
                    className="font-normal text-[16px] leading-[24px]"
                    text={`Principal Investigator: ${orders?.activeOrder?.site?.pi?.name}`}
                  />
                </div>
              </>
            )}
            {orders?.activeOrder?.orderCode && (
              <>
                <div className="w-[6px] h-[6px] rounded-full bg-[#2C2C2C]" />
                <div className="flex items-center gap-1">
                  <BlackBarcode />

                  <HemaValue
                    className="font-normal text-[16px] leading-[24px]"
                    text={`Order Code: ${orders?.activeOrder?.orderCode}`}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <Alert type="error" />
        <div className="bg-white rounded-[5px] pt-[16px] mb-[15px] inventory-tabs tabs_head_space">
          <Tabs
            activeKey={activeTab}
            id="controlled-tab-example"
            className="mb-4"
            onSelect={(key) => {
              setactiveTab(key);
            }}
          >
            <Tab
              eventKey="information"
              title={
                <div className="flex items-center title-icon gap-[7px]">
                  {activeTab === "information" ? (
                    <TabInfo purple />
                  ) : (
                    <TabInfo />
                  )}
                  Request Information
                </div>
              }
            >
              <RequestInformaction
                setUpdatedData={setUpdatedData}
                setformName={setformName}
                setFormIcon={setFormIcon}
                setokBtnIcon={setokBtnIcon}
                setokBtnText={setokBtnText}
                setCTA={setCTA}
                ref={formikControl}
                setShowDashboard={setShowDashboard}
                setCancelBtnText={setCancelBtnText}
                setItems={setItems}
                items={items}
                setRequestLoader={setRequestLoader}
                setFormValidation={setFormValidation}
              />
            </Tab>
            <Tab
              eventKey="Preparation"
              title={
                <div className="flex items-center title-icon gap-[7px] ">
                  {activeTab === "Preparation" ? (
                    <Assemblies purple />
                  ) : (
                    <Assemblies />
                  )}
                  Preparation
                </div>
              }
              disabled={
                orders.activeOrder?.acknowledgment?.isAccepted ? false : true
              }
            >
              <RequestPreparation
                setUpdatedData={setUpdatedData}
                setformName={setformName}
                setFormIcon={setFormIcon}
                setokBtnIcon={setokBtnIcon}
                setokBtnText={setokBtnText}
                buildPreparation={buildPreparation}
                setbuildPreparation={setbuildPreparation}
                stagingPreparation={stagingPreparation}
                setsubTab={setsubTab}
                orders={orders}
                setCancelBtnText={setCancelBtnText}
                setForm={setForm}
                setopenRequestDoc={setopenRequestDoc}
                setCTA={setCTA}
                setstagingPreparation={setstagingPreparation}
                setFormValidation={setFormValidation}
                subTab={subTab}
                buildAssignkitItems={buildAssignkitItems}
              />
            </Tab>
            <Tab
              eventKey="Shipping"
              title={
                <div className="flex items-center title-icon gap-[7px]">
                  {activeTab === "Shipping" ? (
                    <TabShipping purple />
                  ) : (
                    <TabShipping />
                  )}
                  Shipping
                </div>
              }
              disabled={
                orders.activeOrder?.acknowledgment?.isAccepted ? false : true
              }
            >
              <h2 className="mb-[16px] text-base font-bold px-[16px] text-primary1">
                Shipments
              </h2>

              <ShippingRequest
                subTab={subTab}
                setsubTab={setsubTab}
                setformName={setformName}
                setFormIcon={setFormIcon}
                setUpdatedData={setUpdatedData}
                setokBtnText={setokBtnText}
                setCancelBtnText={setCancelBtnText}
                orders={orders}
                setokBtnIcon={setokBtnIcon}
                setCTA={setCTA}
                setPayloadQc={setPayloadQc}
                setShippingTabs={setShippingTabs}
                payloadQc={payloadQc}
                ref={componentRef}
              />
            </Tab>

            <Tab
              eventKey="Assignment"
              title={
                <div className="flex items-center title-icon gap-[7px]">
                  {activeTab === "Assignment" ? (
                    <TabTask purple />
                  ) : (
                    <TabTask />
                  )}
                  Task Assignment
                </div>
              }
              disabled={orders.activeOrder ? false : true}
            >
              <TaskAssignment
                ref={formikControlAssigment}
                setLoaderAssignment={setLoaderAssignment}
              />
            </Tab>

            <Tab
              eventKey="Notebook"
              title={
                <div className="flex items-center title-icon gap-[7px]">
                  {activeTab === "Notebook" ? (
                    <TabNotebook purple />
                  ) : (
                    <TabNotebook />
                  )}
                  Notebook
                </div>
              }
              disabled={
                orders.activeOrder?.acknowledgment?.isAccepted ? false : true
              }
            >
              <NoteBook
                subTab={subTab}
                setsubTab={setsubTab}
                setformName={setformName}
                setFormIcon={setFormIcon}
                setUpdatedData={setUpdatedData}
                setokBtnText={setokBtnText}
                setCancelBtnText={setCancelBtnText}
                orders={orders}
                setokBtnIcon={setokBtnIcon}
                setCTA={setCTA}
                setPayloadQc={setPayloadQc}
                setShippingTabs={setShippingTabs}
                payloadQc={payloadQc}
                ref={componentRef}
              />
            </Tab>
          </Tabs>
        </div>
        <div className="bg-white rounded-[5px] inventory-tabs">
          {activeTab === "information" && (
            <div className="w-full flex items-center justify-between px-[16px] py-[21px]  rounded-[5px] bg-white ">
              {requestLoader ? (
                <Button
                  type="button"
                  bg="bg-primary1"
                  text={
                    <>
                      <Spinner animation="grow" size="sm" variant="light" />
                      <Spinner animation="grow" size="sm" variant="light" />
                      <Spinner animation="grow" size="sm" variant="light" />
                    </>
                  }
                />
              ) : (
                <div className="flex items-center justify-between w-full">
                  <div className="flex gap-[10px]">
                    <Button
                      type="button"
                      text="Close"
                      bg="bg-primary1"
                      color="text-white"
                      icon={<PreviousIcon color="#fff" />}
                      cta={() => {
                        setShowDashboard(true);
                        navigate("/");
                      }}
                    />
                    {common?.permissions?.["Kit Order Process"]?.update && (
                      <Button
                        type="button"
                        text="Save"
                        bg="bg-primary1"
                        color="text-white"
                        icon={<Confirm />}
                        disabled={
                          orders.activeOrder?.acknowledgment?.isAccepted
                        }
                        cta={async () => {
                          if (activeTab === "information") {
                            const checkItem = items?.filter(
                              (item) => item?.quantity > 2147483647,
                            );
                            if (checkItem?.length > 0) {
                              dispatch(
                                showErrorReducer(
                                  "Quantity must be less than 2147483647 for each item.",
                                ),
                              );
                              window.scrollTo(0, 0);
                              return false;
                            }
                          }

                          formikControl.current?.submitForm();
                        }}
                      />
                    )}
                  </div>
                  <div className="flex items-center gap-2">
                    <Button
                      type="button"
                      text="Previous"
                      bg="bg-white"
                      border="border-primary1"
                      color="text-primary1"
                      icon={<PreviousIcon />}
                      disabled
                    />

                    <Button
                      type="button"
                      text="Next"
                      bg="bg-primary1"
                      color="text-white"
                      icon={<NextIcon />}
                      disabled={
                        orders.activeOrder?.acknowledgment?.isAccepted
                          ? false
                          : true
                      }
                      cta={() => {
                        setactiveTab("Preparation");
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {activeTab === "Assignment" && (
            <div className="w-full flex items-center justify-between px-[16px] py-[21px]  rounded-[5px] bg-white ">
              <div className="flex items-center justify-end gap-2">
                {loaderAssignment ? (
                  <Button
                    type="button"
                    bg="bg-primary1"
                    text={
                      <>
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                      </>
                    }
                  />
                ) : (
                  common?.permissions?.["Kit Order Process"]?.update && (
                    <Button
                      type="button"
                      text="Save"
                      bg="bg-primary1"
                      color="text-white"
                      icon={<Confirm />}
                      disabled={loaderAssignment}
                      cta={async () => {
                        const result =
                          await formikControlAssigment?.current?.validateForm();
                        formikControlAssigment?.current?.submitForm();
                        if (Object.keys(result).length === 0) {
                          setLoaderAssignment(true);
                        }
                      }}
                    />
                  )
                )}
                <Button
                  type="button"
                  text="Close"
                  bg="bg-primary1"
                  color="text-white"
                  icon={<PreviousIcon color="#fff" />}
                  cta={() => {
                    // setShowDashboard(true);
                    setShowDashboard(true);
                    navigate("/");
                  }}
                />
              </div>

              <div className="flex items-center gap-2">
                <Button
                  type="button"
                  text="Previous"
                  bg="bg-white"
                  border="border-primary1"
                  color="text-primary1"
                  icon={<PreviousIcon />}
                  cta={() => {
                    setactiveTab("Shipping");
                  }}
                />

                <Button
                  type="button"
                  text="Next"
                  bg="bg-primary1"
                  color="text-white"
                  icon={<NextIcon />}
                  cta={() => {
                    setactiveTab("Notebook");
                  }}
                />
              </div>
            </div>
          )}
          {activeTab === "Notebook" && (
            <div className="w-full flex items-center justify-between px-[16px] py-[21px]  rounded-[5px] bg-white ">
              <div className="flex items-center justify-between w-full">
                <div className="flex gap-[10px]">
                  <Button
                    type="button"
                    text="Close"
                    bg="bg-primary1"
                    color="text-white"
                    icon={<PreviousIcon color="#fff" />}
                    cta={() => {
                      setShowDashboard(true);
                      navigate("/");
                    }}
                  />
                </div>
                <div className="flex items-center gap-2">
                  <Button
                    type="button"
                    text="Previous"
                    bg="bg-white"
                    border="border-primary1"
                    color="text-primary1"
                    icon={<PreviousIcon />}
                    cta={() => {
                      setactiveTab("Assignment");
                    }}
                  />

                  <Button
                    type="button"
                    text="Next"
                    bg="bg-primary1"
                    color="text-white"
                    icon={<NextIcon />}
                    disabled
                  />
                </div>
              </div>
            </div>
          )}
          {activeTab === "Shipping" && (
            <div className="w-full flex items-center justify-between px-[16px] py-[21px]  rounded-[5px] bg-white ">
              <div className="flex items-center justify-end gap-2">
                <Button
                  type="button"
                  text="Close"
                  bg="bg-primary1"
                  color="text-white"
                  icon={<Confirm />}
                  cta={() => {
                    navigate("/");
                    // setShowDashboard(true)
                    setShowDashboard(true);
                  }}
                />
              </div>

              <div className="flex items-center justify-end gap-2">
                <Button
                  type="button"
                  text="Previous"
                  bg="bg-white"
                  border="border-primary1"
                  color="text-primary1"
                  icon={<PreviousIcon />}
                  cta={() => {
                    // if (!orders.activeShipment?.id) {
                    //   setactiveTab('Preparation');
                    // } else if (subTab === 'Packaging') {
                    //   setactiveTab('Preparation');
                    // } else if (subTab === 'GreenLight') {
                    //   setsubTab('Packaging');
                    // } else if (subTab === 'QualityControl') {
                    //   setsubTab('GreenLight');
                    // } else if (subTab === 'PostShipping') {
                    //   setsubTab('QualityControl');
                    // }
                    setactiveTab("Preparation");
                  }}
                />
                {subTab === "QualityControl" && (
                  <>
                    <Button
                      type="button"
                      text="Print"
                      bg="bg-primary1"
                      color="text-white"
                      icon={<Print />}
                      cta={() => {
                        handlePrint();
                      }}
                    />
                    {common?.permissions?.["Kit Order Process"]?.update && (
                      <Button
                        type="button"
                        text="Ship"
                        bg="bg-primary1"
                        color="text-white"
                        className="pt-[4px] pb-[4px]"
                        icon={<TabShipping color="#fff" />}
                        cta={async (e) => {
                          const temp = e.target.innerHTML;
                          e.target.innerHTML = "Loading ...";
                          const result = await shiptheShipmentAction(
                            orders.activeOrder?.id,
                            orders?.activeShipment?.id,
                          );
                          console.log("result", result);
                          e.target.innerHTML = temp;
                          if (result.status === 200) {
                            await getAllShippingStatsAction(
                              orders.activeOrder?.id,
                            );
                            await getOrderDetailAction(orders.activeOrder?.id);
                            await getShipmentDetailAction(
                              orders.activeOrder?.id,
                              orders?.activeShipment?.id,
                            );
                            await getQualityControlItemAction(
                              orders.activeOrder?.id,
                              orders?.activeShipment?.id,
                            );
                            dispatch(
                              showSuccessReducer(
                                `Item is successfully Shipped`,
                              ),
                            );
                          }
                        }}
                        disabled={
                          orders?.activeShipment?.status?.id === 2 ||
                          orders?.qualityControlItems?.items?.filter(
                            (item) => !item.qualityCheck,
                          )?.length
                            ? true
                            : false
                        }
                      />
                    )}
                  </>
                )}

                <Button
                  type="button"
                  text="Next"
                  bg="bg-primary1"
                  color="text-white"
                  icon={<NextIcon />}
                  disabled={
                    orders?.qualityControlItems?.items?.filter(
                      (item) => !item.qualityCheck,
                    )?.length
                      ? true
                      : false
                  }
                  cta={() => {
                    setactiveTab("Assignment");
                  }}
                />
              </div>
            </div>
          )}
          {activeTab === "Preparation" && (
            <div className="w-full flex items-center justify-between px-[16px] py-[21px]  rounded-[5px] bg-white ">
              <div>
                <Button
                  type="submit"
                  text="Close"
                  bg="bg-primary1"
                  color="text-white"
                  icon={<PreviousIcon color="#fff" />}
                  cta={() => {
                    setShowDashboard(true);
                    navigate("/");
                  }}
                />
              </div>
              <div className="flex items-center justify-end gap-2">
                <Button
                  type="button"
                  text="Previous"
                  bg="bg-white"
                  border="border-primary1"
                  color="text-primary1"
                  icon={<PreviousIcon />}
                  cta={() => {
                    setactiveTab("information");
                  }}
                />
                {subTab === "staging" &&
                  common?.permissions?.["Kit Order Process"]?.update && (
                    <div className="flex items-center justify-end gap-2">
                      <Button
                        cta={() => {
                          setformName("Add to Shipment");
                          setFormIcon(<CreateItemPurple />);
                          setokBtnText("Confirm");
                          setCancelBtnText("Cancel");
                          if (
                            orders?.allShipping?.filter(
                              (data) => !data.isLocked,
                            )?.length === 0
                          ) {
                            addtoShipment[4].disabled = true;
                          } else {
                            addtoShipment[4].disabled = false;
                          }
                          addtoShipment[1].disabled = false;

                          addtoShipment[4].options =
                            orders?.allShipping?.filter(
                              (data) => !data.isLocked,
                            );
                          addToShipmentAction();
                          setUpdatedData(addtoShipment);

                          dispatch(
                            setForm({
                              state: true,
                              type: "add-to-shiment",
                            }),
                          );
                        }}
                        type="submit"
                        text="Add to Shipment"
                        bg="bg-primary1"
                        color="text-white"
                        icon={<Add color="#fff" />}
                        disabled={
                          stagingPreparation?.filter((stage) => stage.checked)
                            ?.length < 1
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}

                <Button
                  type="button"
                  text="Next"
                  bg="bg-primary1"
                  color="text-white"
                  icon={<NextIcon />}
                  cta={() => {
                    setactiveTab("Shipping");
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {uisettings?.openform && (
          <FormContainer
            cta={CTA}
            formType={updatedData}
            formName={formName}
            icon={formIcon}
            formValidation={formValidation}
            setUpdatedData={setUpdatedData}
            setformName={setformName}
            setFormIcon={setFormIcon}
            allItemsInTemplate={allItemsInTemplate}
            dropDownItemList={dropdownItemList}
            setCancelBtnText={setCancelBtnText}
            cancelBtnIcon={cancelBtnIcon}
            cancelBtnText={cancelBtnText}
            okBtnIcon={okBtnIcon}
            okBtnText={okBtnText}
            setokBtnIcon={setokBtnIcon}
            setokBtnText={setokBtnText}
            setCTA={setCTA}
            addInventoryModal={addInventoryModal}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        <RequestDocumentForm />
      </>
    );
  }
};

export default CreateKit;
