import React, { useEffect } from 'react';

const PageLayout = ({ children }) => {
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css';  // Tailwind CDN (or use your own build)

    document.head.appendChild(link);
    console.log('loaded page layout')

    return () => {
      document.head.removeChild(link);
      console.log('Removed stylesheet from head');
    };
  }, []);

  return <div>{children}</div>;
};

export default PageLayout;
