import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert as BootstrapAlert, Placeholder } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Skeleton from "react-loading-skeleton";
import { filter } from "smart-array-filter";
import { Alert, Button, FormSearch, HemaValue, Pagination, FilterColumn, NoDataComponent, HemaLabel, SingleSelect } from "../../../utils";
import { ExpiryAlertPurple, DeletePurple, Edit, TableSortIcon, Message, FirstName, Barcode, Location, Delete } from "../../../HemeIconLibrary";
import deleteIcon from "../../../assets/images/deleteIcon.svg";

import { sortedData } from "../../../helpers/sort";
import { SystemUserValidation } from "../../../components/Formik/allFormValidation";
import { addPortalUserFields } from "../../../components/Formik/AllForms/addPortalUserFields";
import { getSitesByStudyIdAction, getCountriesByStudyIdAction, getSitesByStudyIdAndCountryIdAction } from '../../../Actions/siteManagment';
import { Remove } from "../../../components/Formik/AllForms/remove";
import { setportalUsersFilter } from "../../../Store/reducers/settings";
import { setForm, editFormReducer, setFormCloseReducer, showSuccessReducer, setFormLoaderReducer } from "../../../Store/reducers/uiSettings";
import {
  getAllSponserAction,
  getAllSponserStudiesAction,
} from "../../../Actions/sponsers";

// Actions
import { getPortalUserAction, getPortalUserByIdAction, updatePortalUserAction, deletePortalUserAction } from "../../../Actions/settings";

// Utils
import { getFullName } from "../Utils";
import { getPermissionsReducerAction, savePermissionReducerAction } from "../../../Actions/permission";
import Select from 'react-select';


const SystemUserList = ({ setCTA, setformName, setFormIcon, setUpdatedData, setokBtnIcon, setokBtnText, setFormValidation }) => {
  const { uisettings, sponsers, sites, selectedStudySubjects, settings, common, studies } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { isLoading, portalUsers, portalUsersFilter } = settings;


  const [module, setModule] = useState();
  const [moduleChanged, setModuleChanged] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [site, setSite] = useState();
  const [siteChanged, setSiteChanged] = useState(false);
  const [sponsorList, setSponsorList] = useState([]);
  const [sponsor, setSponsor] = useState();
  const [sponsorChanged, setSponsorChanged] = useState(false);
  const [studyList, setStudyList] = useState([]);
  const [study, setStudy] = useState();
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState();
  const [isPermissionPopupOpen, setIsPermissionPopupOpen] = useState(false);
  const [studyChanged, setStudyChanged] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [userId, setUserId] = useState();
  
  const openPermissionPopup = () => {
    console.log('open popup')
    setIsPermissionPopupOpen(true);
  };

  const closePermissionPopup = () => {
    setIsPermissionPopupOpen(false);
  };

  //callallEnventoryRequiredAPI
  useEffect(() => {
    (() => {
      getSponsorsEffect();
    })();
  }, []);

  const getSponsorsEffect = async()=> {
    let result = await getAllSponserAction();
    setSponsorList(result.data);
  }
  
  useEffect(() => {
    (() => {
      sponsorChangedEffect();
    })();
  }, [sponsor]);

  const sponsorChangedEffect = async()=> {
    setStudy(null);
    setSite(null);
    setStudyList([]);
    setSiteList([]);
    getPortalUserAction();
  }

  const setSponsorFieldValue = async (x, id) => {
    setSponsor(id);
    setStudy(null);
    setSponsorChanged(true);
    if (Number.isInteger(id) && id != 0){
      let result = await getAllSponserStudiesAction(id);
      let temp = result.data;
      temp = temp.map(item => ({id: item?.id, label: item?.name}));
      setStudyList(temp);
    }
  }
  
  useEffect(() => {
    (async () => {
      studyChangedEffect();
    })();
  }, [study]);

  const studyChangedEffect = async()=> {
      setCountry(null);
      setCountryList([]);
      setSite(null);
      setSiteList([]);
      setSite(null);
      if (study) {
        // getStudySubjectsAction(study.id);
        let result = await getSitesByStudyIdAction(study.id);
        let sites = result?.data ? result?.data : [];
        sites = sites.map(item=> ({id: item?.id, label: item?.name}));
        setSiteList(sites);

        result = await getCountriesByStudyIdAction(study.id);
        let temp = result.data.map(item => ({id: item?.id, label: item?.name}));
        setCountryList(temp);
      }
  }

  useEffect(() => {
    (async () => {
      if (study && country) {
        let result = await getSitesByStudyIdAndCountryIdAction(study.id, country.id);
        let temp = result.data.map(item => ({id: item?.id, label: item?.name}));
        setSiteList(temp);
      }
    })();
  }, [country]);

  const [searchQuery, setsearchQuery] = useState("");
  const [dataList, setDataList] = useState(null);
  const [permissionList, setPermissionList] = useState([
    {
      permissionType: "Read Access",
      study: "Clinical Trial A",
      country: "USA",
      site: "New York Research Center",
    },
    {
      permissionType: "Write Access",
      study: "Cancer Research B",
      country: "Germany",
      site: "Berlin Health Institute",
    },
    {
      permissionType: "Admin Access",
      study: "COVID-19 Vaccine Study",
      country: "UK",
      site: "London Medical Lab",
    },
    {
      permissionType: "Read Access",
      study: "Diabetes Study C",
      country: "Canada",
      site: "Toronto Medical Research",
    },
    {
      permissionType: "Write Access",
      study: "Heart Disease Trial D",
      country: "France",
      site: "Paris Cardiology Center",
    },
    {
      permissionType: "Read Access",
      study: "Neuroscience Study E",
      country: "Japan",
      site: "Tokyo Brain Institute",
    }
  ]);

  //senpnarch for location
  useEffect(() => {
    (async () => {
      if (searchQuery) {
        const filteredDataResult = filter(portalUsers, {
          keywords: searchQuery, // search for any field that contains the "Do" string

          caseSensitive: false,
        });
        setDataList(filteredDataResult);
      } else {
        setDataList(portalUsers);
      }
    })();
  }, [searchQuery, portalUsers]);

  const editSystemUser = (row) => {
    setCTA(() => async (payload) => {
      const filterpayload = {
        ...payload,
        siteIds: payload.siteIds
          ?.filter((site) => site.checked === true)
          ?.map((site) => {
            return site.id;
          }),
      };
      dispatch(setFormLoaderReducer(true));
      const resp = await updatePortalUserAction(row.id, filterpayload);
      dispatch(setFormLoaderReducer(false));
      if (resp?.status === 200) {
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`User updated.`));
        getPortalUserAction();
      }
    });
    //todo: I commented out these two lines because I am experiencing an error when they run, I will solve this later.
    // setFormValidation((error, values) => (error, values) => {
    //   SystemUserValidation(error, values);
    // });
  };

  const deleteSystemUser = (row) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true));
      const resp = await deletePortalUserAction(row.id, data.change_reason);
      if (resp?.status === 200) {
        dispatch(showSuccessReducer(`User deleted.`));
        dispatch(setFormCloseReducer());
      }
      dispatch(setFormLoaderReducer(false));
    });
  };

  useEffect(() => {
    if (Object.keys(portalUsersFilter)?.length) {
      const filterResult = portalUsers?.filter((port) => {
        if (
          (portalUsersFilter.email?.length ? portalUsersFilter.email.includes(port.email) : true) &&
          (portalUsersFilter.status?.length ? portalUsersFilter.status?.includes(port.status) : true) &&
          (portalUsersFilter.firstName?.length ? portalUsersFilter.firstName?.includes(getFullName(port)) : true)
        ) {
          return true;
        } else {
          return false;
        }
      });
      setDataList(filterResult);
    } else {
      setDataList(portalUsers);
    }
  }, [JSON.stringify(portalUsersFilter)]);



  const handleOpenPermissionModal = async (userId)=> {
    setUserId(userId);
    resetPermissionsModal();
    fetchPermissions(userId);
    openPermissionPopup();
  }

  const resetPermissionsModal = ()=> {
    setModule(null);
    setModuleChanged(false);
    setSponsor(null);
    setSponsorChanged(false);
    setStudy(null);
    setStudyChanged(false);
    setCountry(null);
    setSite(null);
    setPermissions([]);
  }

  const fetchPermissions = async (userId)=> {
    let result = await getPermissionsReducerAction(userId);
    if (!result) {
      result = [];
    }
    setPermissions(result);
  }

  const handleSave = async ()=> {
    if(!module || !sponsor || !study) {
      return;
    }
    await savePermissionReducerAction({ portalUserId: userId, moduleTypeId: module, 
      studyId: study.id, countryId: country?.id ? country?.id : null, siteId: site?.id ? site.id : null });
    fetchPermissions(userId);
    dispatch(
                  showSuccessReducer(
                    `Permission saved.`,
                  ),
                )
  }

  const SkelatonCoponent = () => {
    return (
      <>
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
      </>
    );
  };

  return (
    <div className="bg-white rounded-[5px] mt-[36px] inventory-tabs">
      <>
        <div className="mb-[16px] px-[16px]">
          <FormSearch w="w-[400px]" searchQuery={searchQuery} setsearchQuery={setsearchQuery} />
        </div>

        {!dataList ? (
          <SkelatonCoponent />
        ) : dataList?.length > 0 || Object.keys(portalUsersFilter)?.length ? (
          <DataTable
            data={dataList}
            className="hdxa-rdt-filter border-t-[1px] border-solid border-[#DEE2E6] managment-table-st"
            noDataComponent={<NoDataComponent setActiveFilterTab={setDataList} activeFilterOriginalData={portalUsers} resetRedux={setportalUsersFilter} />}
            columns={[
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={"Name"} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="firstName"
                          secondColumnName="lastName"
                          setRedux={setportalUsersFilter}
                          reduxValues={portalUsersFilter || []}
                          options={Array.from(new Set(portalUsers.map((filter) => getFullName(filter))))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={getFullName(row)} color="text-textColor" className="text-xs font-medium" />,
                sortId: "firstName",
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={"Email"} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="email"
                          setRedux={setportalUsersFilter}
                          reduxValues={portalUsersFilter || []}
                          options={Array.from(new Set(portalUsers.map((filter) => filter.email)))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row.email} color="text-textColor" className="text-xs font-medium" />,
                sortId: "email",
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={"Status"} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="status"
                          options={Array.from(new Set(portalUsers.map((filter) => filter.status)))}
                          setRedux={setportalUsersFilter}
                          reduxValues={portalUsersFilter || []}
                          // type="boolean"
                          // boolTrueText="Active"
                          // boolFalseText="In-Active"
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                selector: (row, index) => (
                  <div
                    className={`flex items-center w-fit gap-1 cursor-pointer px-2 py-1 rounded-full ${
                      (row?.status === "Pending Invitation" && "bg-[#F6EEE6]") ||
                      (row?.status === "Active" && "bg-[#E6F6EB]") ||
                      (row?.status === "Inactive" && "bg-[#EFEFEF]") ||
                      (row?.status === "Locked" && "bg-[#EFEFEF]")
                    }`}
                  >
                    <div
                      className={`w-2 h-2 rounded-full ${
                        (row?.status === "Pending Invitation" && "bg-[#F97316]") ||
                        (row?.status === "Active" && "bg-[#138846]") ||
                        (row?.status === "Inactive" && "bg-[#454545]") ||
                        (row?.status === "Locked" && "bg-[#454545]")
                      }`}
                    />

                    <HemaValue
                      className="text-xs"
                      color={
                        (row?.status === "Pending Invitation" && "text-[#F97316]") ||
                        (row?.status === "Active" && "text-[#138846]") ||
                        (row?.status === "Inactive" && "text-[#454545]") ||
                        (row?.status === "Locked" && "text-[#454545]")
                      }
                      text={row?.status}
                    />
                  </div>
                ),

                sortId: "status",
              },
              {
                name: (
                  <div className="w-full">
                    <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      {" "}
                      <HemaValue text={"Actions"} color="text-[#000000]" className="text-xs font-normal pr-7" />{" "}
                    </div>
                  </div>
                ),
                cell: (row, index) => {
                  return (
                    <div className="flex">
                        {common?.permissions?.["Portal User Management"]?.update && (
                          <Button
                            icon={<Message color="#775FD5" />}
                            padding={false}
                            color="text-[#dc2626]"
                            bg="bg-bgActionDots"
                            cta={()=> {
                              handleOpenPermissionModal(row.id);
                            } }
                          />
                        )}
                        {common?.permissions?.["Portal User Management"]?.update && (
                          <Button
                            icon={<Edit color="#775FD5" />}
                            padding={false}
                            color="text-[#dc2626]"
                            bg="bg-bgActionDots"
                            cta={async () => {
                              dispatch(editFormReducer(row));
                              setformName("Edit user");
                              setokBtnIcon();
                              setokBtnText("Update");
                              setFormIcon(<ExpiryAlertPurple />);
                              const resp = await getPortalUserByIdAction(row.id);
                              const selectedSites = resp.data;

                              addPortalUserFields[0].initialValue = row.firstName;
                              addPortalUserFields[1].initialValue = row.lastName;
                              addPortalUserFields[2].initialValue = row.email;
                              addPortalUserFields[2].disabled = true;
                              addPortalUserFields[3].initialValue = row.isActive;
                              addPortalUserFields[4].initialValue = row.isLocked;
                              const selectedEdit =
                                settings?.sites?.map((obj) => {
                                  const found = selectedSites?.sites?.find((objSite) => objSite.site.id === obj.id);
                                  return {
                                    ...obj,
                                    checked: found ? true : false,
                                  };
                                }) || [];
                              addPortalUserFields[5].initialValue = selectedEdit;

                              setUpdatedData(addPortalUserFields);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: "create-document-language",
                                }),
                              );
                              editSystemUser(row);
                            }}
                          />
                        )}
                        {/* <Button
                            icon={<SearchColor color="#775FD5" />}
                            padding={false}
                            color="text-white"
                            bg="bg-bgActionDots"
                          /> */}
                        {common?.permissions?.["Portal User Management"]?.delete && (
                          <Button
                            icon={<Delete color="#775FD5" />}
                            padding={false}
                            color="text-[#dc2626]"
                            bg="bg-bgActionDots"
                            cta={() => {
                              dispatch(editFormReducer(row));
                              Remove[0].label = "User Name";
                              Remove[0].initialValue = row?.firstName + " " + row?.lastName;
                              setUpdatedData(Remove);
                              setokBtnIcon();
                              setokBtnText("Confirm");
                              setformName("Delete user");
                              setFormIcon(<DeletePurple />);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: "deleteItem",
                                }),
                              );
                              deleteSystemUser(row);
                            }}
                          />
                        )}
                    </div>
                  );
                },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
              },
            ]}
            customStyles={{
              rows: {
                style: {
                  // paddingRight: "20px",
                  style: { overflow: "visible !important" },
                },
              },

              cells: {
                style: { overflow: "visible !important" },
              },

              responsiveWrapper: {
                style: { overflow: "visible !important" },
              },
            }}
            pagination
            onSort={(row, direction, sorted) => {
              setDataList(sortedData(row.sortId, direction, sorted)?.filter((data) => Object.keys(data)?.length));
            }}
            paginationComponent={(e) => {
              return <Pagination e={e} />;
            }}
          />
        ) : (
          <BootstrapAlert variant="warning" className="mt-3 text-center">
            There is no data found.
          </BootstrapAlert>
        )}
      </>


      {isPermissionPopupOpen && (
        <div className="site-popup">
          <div className="site-popup-content">
            <span className="site-close" onClick={closePermissionPopup}>×</span>
            <h2>Permissions</h2>
            <Alert />
            <div class="flex mb-[16px] gap-[16px]">

              <div className={`w-full `}>
                <HemaLabel
                    text="Module"
                    className={'mb-[10px]'}
                    required={true}
                    icon={<FirstName />}
                />
                <SingleSelect
                    name={'Module'}
                    // onChange={handleChange}
                    setFieldValue={(e, r) => {
                        setModuleChanged(true);
                        setModule(r);
                      }
                    }
                    value={''}
                    options= {[{id: 1, name: 'Kits'}, {id: 2, name: 'Reports'}]}
                //  formikRef={ref}
                />
                {moduleChanged && !module && <span style={{ color: "red" }}>Required</span>}

              </div>

              <div className={`w-full `}>
                <HemaLabel
                    text="Sponsor"
                    className={'mb-[10px]'}
                    required={true}
                    icon={<FirstName />}
                />
                <SingleSelect
                    name={'sponsor'}
                    // onChange={handleChange}
                    setFieldValue={(e, r) => {
                        setSponsorFieldValue(e, r)
                    }
                    }
                    value={sponsor}
                    options={sponsers?.allSponsers?.filter((data) => data.isActive) || []}
                //  formikRef={ref}
                />
                {sponsorChanged && !sponsor && <span style={{ color: "red" }}>Required</span>}

              </div>

              <div className={`w-full`}>
                <HemaLabel
                    text="Study #"
                    className={'mb-[10px]'}
                    required={true}
                    icon={<Barcode purple />}
                />
                <Select
                  name='study'
                  options={studyList}
                  value={study ? study : null}
                  onChange={(e) => {
                      setStudy(e);
                      setStudyChanged(true)
                    }
                  }
                  isClearable
                  isSearchable
                  className="hema-single-select"
                />
                {studyChanged && !study && <span style={{ color: "red" }}>Required</span>}
              </div>

              <div className={`w-full`}>
                <HemaLabel
                    text="Country #"
                    className={'mb-[10px]'}
                    icon={<Barcode purple />}
                />
                <Select
                  name='country'
                  options={countryList}
                  value={country ? country : null}
                  onChange={(e) => {
                      setCountry(e);
                    }
                  }
                  isClearable
                  isSearchable
                  className="hema-single-select"
                />
                {/* {studyChanged && !study && <span style={{ color: "red" }}>Required</span>} */}
              </div>
              
              <div className={`w-full`}>
                  <HemaLabel
                      text="Site #"
                      className={'mb-[10px]'}
                      icon={<Barcode purple />}
                  />
                  <Select
                    name='site'
                    options={siteList}
                    value={site ? site : null}
                    onChange={(e) => {
                      setSite(e)
                      setSiteChanged(true);
                      }
                    }
                    isClearable
                    isSearchable
                    className="hema-single-select"
                  />
              </div>

            </div>


            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                <button
                  type="button"
                  className="handle-save-btn rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>

            <>
            <DataTable
            data={permissions}
            className="hdxa-rdt-filter border-t-[1px] border-solid border-[#DEE2E6] managment-table-st"
            noDataComponent={<NoDataComponent setActiveFilterTab={setDataList} activeFilterOriginalData={portalUsers} resetRedux={setportalUsersFilter} />}
            columns={[
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={"Permission Type"} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row.moduleTypeId == 1? 'Kits' : 'Reports'} color="text-textColor" className="text-xs font-medium" />,
                sortId: "moduleTypeId",
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={"Study"} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row?.study?.name} color="text-textColor" className="text-xs font-medium" />,
                sortId: "study.name",
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={"Country"} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                    </div>
                  </div>
                ),
                sortable: true,
                selector: (row, index) => <HemaValue text={row.country?.name || 'Any'} color="text-textColor" className="text-xs font-medium" />,
                sortId: "country.name",
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={"Site"} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                    </div>
                  </div>
                ),
                sortable: true,
                selector: (row, index) => <HemaValue text={row.site?.name || 'Any'} color="text-textColor" className="text-xs font-medium" />,
                sortId: "site.name",
              },
            ]}
            customStyles={{
              rows: {
                style: {
                  // paddingRight: "20px",
                  style: { overflow: "visible !important" },
                },
              },

              cells: {
                style: { overflow: "visible !important" },
              },

              responsiveWrapper: {
                style: { overflow: "visible !important" },
              },
            }}
            pagination
            onSort={(row, direction, sorted) => {
              // setDataList(sortedData(row.sortId, direction, sorted)?.filter((data) => Object.keys(data)?.length));
            }}
            paginationComponent={(e) => {
              return <Pagination e={e} />;
            }}
          />
            </>
            <div className="site-footer">
              <button className="btn btn-danger" onClick={()=> closePermissionPopup() }>Close</button>
            </div>
          </div>
        </div>
      )}
<style jsx>{`
        .handle-save-btn {
          background-color: rgb(96 93 175 / var(--tw-bg-opacity, 1));
        }
        .site-popup {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .site-popup-content {
          width: 1000px;
          min-height: 500px;
          background-color: white;
          padding: 20px;
          border-radius: 5px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }
        .site-close {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 20px;
          cursor: pointer;
        }
        .site-footer {
          display: flex;
          justify-content: end;
        }
      `}</style>

    </div>
  );
};

export default SystemUserList;
