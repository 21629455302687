import { post, get, put, remove } from './http';
import { errorHandling } from './error';

const getAllCases = async (data) => {
    try {
      return await get(`study/cases`, data);
    } catch (e) {
      console.log(e);
    }
};
const getAllCasesByStudyAndSite = async (studyId, siteId) => {
  try {
    return await get(`study/${studyId}/site/${siteId}/cases`);
  } catch (e) {
    console.log(e);
  }
};
const getCaseByAccessionNumber = async (accessionNumber) => {
  try {
    return await get(`study/site/cases/accession-number/${accessionNumber}`);
  } catch (e) {
    console.log(e);
  }
};
const createNewCase = async (data) => {
  try {
    return await post(`study/cases`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
  }
};
const createNewSample= async (data) => {
  try {
    return await post(`study/case/samples`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
  }
};
const updateCaseResult = async (id, data) => {
  try {
    return await put(`study/cases/${id}/result`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
  }
};
const getNextAccessionNumber = async (casePrefixId)=>{
  try {
    return await get(`study/cases/next-accession-number/${casePrefixId}`);
  } catch (err) {
    errorHandling(err?.response?.data);
  }
}
const getCaseBasicInfoByAccessionNumber = async (accessionNumber) => {
  try {
    return await get(`study/site/cases/accession-number/${accessionNumber}/base`);
  } catch (e) {
    console.log(e);
  }
};
const getCaseReportTemplate = async (caseId)=>{
  try {
    return await get(`study/cases/${caseId}/report-template`);
  } catch (err) {
    errorHandling(err?.response?.data);
  }
}
const signOffCaseReport = async (caseId, data=null)=>{
  try {
    return await post(`study/cases/${caseId}/report/sign-off`, data, {
      headers: { accept: '*/*', 'Content-Type': 'multipart/form-data' },
    });
  } catch (err) {
    errorHandling(err?.response?.data);
  }
}

export { getCaseBasicInfoByAccessionNumber, createNewSample, getAllCasesByStudyAndSite, getCaseByAccessionNumber, getAllCases, createNewCase, updateCaseResult, getNextAccessionNumber, getCaseReportTemplate, signOffCaseReport };