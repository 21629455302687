import {
  verifyUserPIN,
  createNewPIN,
} from '../Services/userPin';

const createNewPINAction = async (data) => {
  const result = await createNewPIN(data);
  return result
};

const verifyUserPINAction = async (data) => {
    const result = await verifyUserPIN( data);
    return result
};

export {
    createNewPINAction,
    verifyUserPINAction
};