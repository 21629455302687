import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allNotes: null,
};

export const InternalNotes = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setAllNotes: (state, action) => {
      state.allNotes = action.payload;
    },

    addNewNotes: (state, action) => {
        if(state.allNotes) {
            state.allNotes = [action.payload,...state.allNotes];
        } else {
            state.allNotes = [action.payload];
        }

      },
  },
});

export const { setAllNotes, addNewNotes } = InternalNotes.actions;

export default InternalNotes.reducer;
